/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// import * as AppPropTypes from '../../lib/PropTypes';
import { removeAccent } from '../../lib/utils';

import styles from '../../../styles/partials/text-background.module.scss';

const propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    withFade: PropTypes.bool,
    textClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    text: null,
    color: '#000',
    height: 400,
    withFade: false,
    textClassName: null,
    className: null,
};

const TextBackground = ({ text, color, height, withFade, textClassName, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        style={{ height, fontSize: height }}
    >
        {withFade ? <div className={styles.fade} /> : null}
        <div className={styles.background} style={{ backgroundColor: color }} />
        <div
            className={classNames([
                styles.text,
                {
                    [textClassName]: textClassName !== null,
                },
            ])}
        >
            {removeAccent(text)}
        </div>
    </div>
);

TextBackground.propTypes = propTypes;
TextBackground.defaultProps = defaultProps;

export default TextBackground;
