/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import { useTrackOnClickLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '../../lib/utils';

import { withTheme } from '../../contexts/SiteContext';
import * as Logos from '../icons/logos';
import Menu from '../menus/Menu';
import Link from '../partials/Link';

import styles from '../../../styles/headers/header-email.module.scss';

const propTypes = {
    theme: PropTypes.oneOf(['normal', 'dark']),
    sticky: PropTypes.bool,
    textColor: AppPropTypes.color,
    backgroundColor: AppPropTypes.color,
    logoComponent: PropTypes.string,
    className: PropTypes.string,
    barClassName: PropTypes.string,
    headerRef: AppPropTypes.ref,
};

const defaultProps = {
    theme: 'normal',
    sticky: false,
    textColor: null,
    backgroundColor: null,
    logoComponent: 'urbania',
    className: null,
    barClassName: null,
    headerRef: null,
};

function HeaderEmail({
    theme,
    sticky,
    textColor,
    backgroundColor,
    logoComponent,
    className,
    barClassName,
    headerRef,
}) {
    const generateUrl = useUrlGenerator();
    const user = useUser();
    const LogoComponent = getComponentFromName(Logos, logoComponent, 'urbania');
    const onClickLogo = useTrackOnClickLink('click_header_logo');
    const [pathname] = useLocation();
    const menuItems = useMemo(
        () => [
            {
                id: 'profile',
                label: <FormattedMessage defaultMessage="Profil" description="Menu item label" />,
                className: classNames([
                    {
                        [styles.active]: pathname === generateUrl('subscriptions.profile'),
                    },
                ]),
                url: generateUrl('subscriptions.profile'),
            },
            {
                id: 'subscriptions',
                label: (
                    <FormattedMessage defaultMessage="Abonnements" description="Menu item label" />
                ),
                className: classNames([
                    {
                        [styles.active]: pathname === generateUrl('subscriptions.manage'),
                    },
                ]),
                url: generateUrl('subscriptions.manage'),
            },
            {
                id: 'logout',
                label: (
                    <FormattedMessage defaultMessage="Déconnexion" description="Menu item label" />
                ),
                className: styles.logout,
                url: generateUrl('auth.logout'),
                external: true,
                target: '_self',
            },
        ],
        [generateUrl, pathname],
    );

    return (
        <div
            className={classNames([
                styles.container,
                styles[`${theme}Theme`],
                {
                    [styles.sticky]: sticky,
                    [className]: className !== null,
                },
            ])}
            ref={headerRef}
        >
            <div
                className={classNames([styles.bar, barClassName])}
                style={{ color: textColor, backgroundColor }}
            >
                <div className={styles.inner}>
                    <div className={styles.left}>
                        {LogoComponent !== null ? (
                            <Link
                                href={generateUrl('home')}
                                className={styles.link}
                                onClick={onClickLogo}
                            >
                                <LogoComponent className={styles.logo} />
                            </Link>
                        ) : null}
                    </div>
                    {user !== null ? (
                        <div className={styles.center}>
                            <Menu
                                items={menuItems}
                                className={styles.menu}
                                itemsClassName={styles.items}
                                itemClassName={styles.item}
                                linkClassName={styles.link}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

HeaderEmail.propTypes = propTypes;
HeaderEmail.defaultProps = defaultProps;

const WithThemeComponent = withTheme(
    HeaderEmail,
    ({ textColor, header: { logoComponent, backgroundColor } = {} }) => ({
        logoComponent,
        textColor,
        backgroundColor,
    }),
);
export default WithThemeComponent;
