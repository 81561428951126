/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useTrackOnClickLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '../../lib/utils';

import { useUrlGenerator } from '@folklore/routes';
import { withTheme } from '../../contexts/SiteContext';
import * as Logos from '../icons/logos';
import Link from '../partials/Link';

import styles from '../../../styles/headers/header-simple.module.scss';

const propTypes = {
    theme: PropTypes.oneOf(['normal', 'dark']),
    sticky: PropTypes.bool,
    textColor: AppPropTypes.color,
    logoComponent: PropTypes.string,
    className: PropTypes.string,
    headerRef: AppPropTypes.ref,
};

const defaultProps = {
    theme: 'normal',
    sticky: false,
    textColor: null,
    logoComponent: 'urbania',
    className: null,
    headerRef: null,
};

function HeaderSimple({ theme, sticky, textColor, logoComponent, className, headerRef }) {
    const generateUrl = useUrlGenerator();
    const LogoComponent = getComponentFromName(Logos, logoComponent, 'urbania');
    const onClickLogo = useTrackOnClickLink('click_header_logo');

    return (
        <div
            className={classNames([
                styles.container,
                styles[`${theme}Theme`],
                {
                    [styles.sticky]: sticky,
                    [className]: className !== null,
                },
            ])}
            ref={headerRef}
        >
            <div className={styles.bar} style={{ color: textColor }}>
                <div className={styles.inner}>
                    <div className={styles.left}>
                        {LogoComponent !== null ? (
                            <Link
                                href={generateUrl('home')}
                                className={styles.link}
                                onClick={onClickLogo}
                            >
                                <LogoComponent className={styles.logo} />
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

HeaderSimple.propTypes = propTypes;
HeaderSimple.defaultProps = defaultProps;

const WithThemeComponent = withTheme(
    HeaderSimple,
    ({ textColor, header: { logoComponent } = {} }) => ({
        logoComponent,
        textColor,
    }),
);
export default WithThemeComponent;
