import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import Meta from './Meta';

const propTypes = {
    section: AppPropTypes.section,
    onChange: PropTypes.func,
};

const defaultProps = {
    section: null,
    onChange: null,
};

function SectionMeta({ section, onChange }) {
    if (section === null) {
        return null;
    }
    const {
        url,
        metadata: {
            title = null,
            description = null,
            image = null,
            imageShare = null,
            keywords = null,
            microformats = null,
            rss_url: rssUrl = null,
            atom_url: atomUrl = null,
        } = {},
    } = section;
    return (
        <Meta
            url={url}
            title={title}
            description={description}
            image={imageShare || image}
            keywords={keywords}
            microformats={microformats}
            rssUrl={rssUrl}
            atomUrl={atomUrl}
            onChange={onChange}
        />
    );
};

SectionMeta.propTypes = propTypes;
SectionMeta.defaultProps = defaultProps;

export default SectionMeta;
