/* eslint-disable import/no-named-as-default */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useSearch } from 'wouter';

import { useArticle } from '../../hooks/usePage';
import useScrollCompletion from '../../hooks/useScrollCompletion';
import useScrollToTop from '../../hooks/useScrollToTop';
import { useImagePixels, useTrackEvent, useTrackOnClickLink } from '../../hooks/useTracking';
import useWindowScrollTo from '../../hooks/useWindowScrollTo';
import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName, getPixelsFromArticles } from '../../lib/utils';

import AdsTargetingProvider from '../../contexts/AdsTargetingContext';
import { useApi } from '../../contexts/ApiContext';
import { useSmallLayout } from '../../contexts/LayoutContext';
import { useSetPage, useSetStatusCode } from '../../contexts/NavigationContext';
import { useSite, withTheme } from '../../contexts/SiteContext';
import More from '../buttons/More';
import ViewMapButton from '../buttons/ViewMap';
import CollectionRow from '../lists/CollectionRow';
import SectionRow from '../lists/SectionRow';
import ArticleMeta from '../meta/ArticleMeta';
import Ad from '../partials/Ad';
import Article from '../partials/Article';
import Detector from '../partials/Detector';
import CommentsSection from '../sections/Comments';
import * as FeaturedSectionComponents from '../sections/featured';

// import LoadingCircle from '../partials/LoadingCircle';
import styles from '../../../styles/pages/article.module.scss';

const propTypes = {
    article: AppPropTypes.article,
    articleSlug: PropTypes.string.isRequired,
    darkBackgroundColor: PropTypes.string,
    articleCompletionTriggers: PropTypes.arrayOf(PropTypes.number),
    isDisabled: PropTypes.bool,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    darkBackgroundColor: null,
    article: null,
    articleCompletionTriggers: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    isDisabled: false,
    onMetadataChange: null,
};

function ArticlePage({
    article,
    articleSlug,
    darkBackgroundColor,
    articleCompletionTriggers,
    isDisabled,
    onMetadataChange,
}) {
    const generateUrl = useUrlGenerator();
    const site = useSite();
    const {
        id: siteId,
        theme: {
            displayMapLinks = false,
            article: {
                maxLoadedArticles = 5,
                withoutComments = false,
                withoutAds = false,
                withoutAddons = false,
                withoutTopAds = false,
                hideCategory = false,
                withoutHeaderBackground = false,
                withoutHeaderColoredBackground = false,
                withoutAuthorLink = false,
                isReader = false,
                featuredSectionComponent = null,
            },
        },
    } = site;

    const shouldDisplayMapButton = displayMapLinks;
    const layoutIsSmall = useSmallLayout();
    const setStatusCode = useSetStatusCode();
    const setArticle = useSetPage();
    const [pathname, setLocation] = useLocation();
    const search = useSearch();
    const onClickViewMap = useTrackOnClickLink('click_article_fixed_view_map');
    const { from_collection: fromCollection = null } = useMemo(
        () => queryString.parse(search),
        [search],
    );

    // Scroll to top when this is the first article rendering
    // const firstArticle = useMemo(() => article, [article !== null]);
    const firstArticleRef = useRef(article);
    if (firstArticleRef.current === null && article !== null) {
        firstArticleRef.current = article;
    }
    useScrollToTop(firstArticleRef.current);

    // Load article
    const lastLoadedArticle = useRef(article);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadedArticles, setLoadedArticles] = useState(article !== null ? [article] : []);
    const onLoad = useCallback(
        (newArticle) => {
            if (newArticle !== null) {
                lastLoadedArticle.current = newArticle;
                setArticle(newArticle);
                setLoadedArticles([...loadedArticles, newArticle]);
            }
            return newArticle;
        },
        [setArticle, loadedArticles],
    );
    const onError = useCallback(() => setStatusCode(404), [setStatusCode]);
    useArticle({
        article,
        slug: articleSlug,
        onLoad,
        onError,
    });

    // Merge current article with loaded articles
    const currentArticles = useMemo(() => {
        const articles = [...loadedArticles];
        if (article !== null && articles.indexOf(article) === -1) {
            articles.push(article);
        }
        return articles;
    }, [loadedArticles, article]);

    // Load next article
    const api = useApi();
    const loadNextArticle = useCallback(() => {
        if (article === null) {
            return;
        }
        setLoadingNext(true);

        // Get ids to exclude
        const currentArticleIds = currentArticles.map((it) => it.id);
        const cookieExcludeIds = (Cookies.get('infinite_exclude') || '')
            .split(',')
            .filter((it) => it.length > 0);
        const currentExcludeIds = [...new Set([...currentArticleIds, ...cookieExcludeIds])];

        const nextArticleQuery = {
            exclude: currentExcludeIds,
        };
        if (fromCollection !== null) {
            nextArticleQuery.collection = fromCollection;
        }
        api.getNextArticle(article, nextArticleQuery)
            .then(onLoad)
            .then((newArticle) => {
                if (newArticle !== null) {
                    setLocation(
                        `${newArticle.url}${
                            fromCollection !== null
                                ? `?${queryString.stringify({ from_collection: fromCollection })}`
                                : ''
                        }`,
                    );

                    // Save id in cookies
                    Cookies.set(
                        'infinite_exclude',
                        [...new Set([...currentExcludeIds, newArticle.id])].join(','),
                        {
                            expires: 1,
                        },
                    );
                }
            })
            .then(() => {
                setLoadingNext(false);
            });
    }, [setLocation, article, fromCollection, api, onLoad, currentArticles, setLoadingNext]);

    // Article row query
    const articlesRowQuery = useMemo(
        () =>
            currentArticles.map(({ id }) => ({
                exclude: id,
            })),
        [currentArticles],
    );

    // Pixels
    const imagePixels = useMemo(() => getPixelsFromArticles(currentArticles), [currentArticles]);
    useImagePixels(imagePixels);

    // Articles in view
    const [articlesInView, setArticlesInView] = useState(currentArticles.map((it) => it.id));
    const [scrolling, setScrolling] = useState(false);
    const onScrollComplete = useCallback(() => setScrolling(false), [setScrolling]);
    const windowScrollTo = useWindowScrollTo({
        onComplete: onScrollComplete,
    });
    const scrollTo = useCallback(
        (...args) => {
            setScrolling(true);
            windowScrollTo(...args);
        },
        [windowScrollTo, setScrolling],
    );

    // Update articles in view
    const onArticleEnter = useCallback(
        (viewArticle) => {
            const currentIndex = articlesInView.findIndex((id) => id === viewArticle.id);
            if (currentIndex === -1) {
                setArticlesInView([...articlesInView, viewArticle.id]);
            }
        },
        [articlesInView, setArticlesInView],
    );
    const onArticleLeave = useCallback(
        (viewArticle) => {
            const currentIndex = articlesInView.findIndex((id) => id === viewArticle.id);
            if (currentIndex !== -1) {
                setArticlesInView(articlesInView.filter((id) => id !== viewArticle.id));
            }
        },
        [articlesInView, setArticlesInView],
    );

    // Update url when articles in view change
    useEffect(() => {
        if (
            loadingNext ||
            scrolling ||
            article === null ||
            articlesInView.length === 0 ||
            isDisabled
        ) {
            return;
        }
        const lastInViewIndex = articlesInView.reduce((lastIndex, id) => {
            const index = currentArticles.findIndex((it) => it.id === id);
            return index > lastIndex ? index : lastIndex;
        }, -1);
        if (lastInViewIndex !== -1) {
            const { url } = currentArticles[lastInViewIndex];
            if (url !== pathname) {
                setLocation(
                    `${url}${
                        fromCollection !== null
                            ? `?${queryString.stringify({ from_collection: fromCollection })}`
                            : ''
                    }`,
                );
            }
        }
    }, [articlesInView, fromCollection, setLocation]);

    // Scroll to article
    useEffect(() => {
        if (!('scrollRestoration' in window.history)) {
            return () => {};
        }
        window.history.scrollRestoration = 'manual';
        return () => {
            window.history.scrollRestoration = 'auto';
        };
    }, []);

    useEffect(() => {
        if (
            article !== null &&
            lastLoadedArticle.current !== article &&
            articlesInView.findIndex((id) => id === article.id) === -1
        ) {
            lastLoadedArticle.current = null;
            scrollTo(`#article-${article.id}`, 0.5);
        }
    }, [article, scrollTo]);

    // Track scrolling
    const trackEvent = useTrackEvent();
    const onScrollCompletionTrigger = useCallback(
        (id, trigger) => {
            const scrollPercent = Math.round(trigger * 100);
            trackEvent('Navigation', 'Scroll', scrollPercent, scrollPercent, {
                articleTitle: article !== null ? article.title : null,
                scrollPercent,
            });
        },
        [trackEvent, article],
    );
    const { ref: scrollCompletionRef } = useScrollCompletion(articlesInView, {
        triggers: articleCompletionTriggers,
        onTrigger: onScrollCompletionTrigger,
    });

    const articlesDetectorProps = useMemo(
        () =>
            currentArticles.map((currentArticle) => ({
                onEnter: () => onArticleEnter(currentArticle),
                onLeave: () => onArticleLeave(currentArticle),
            })),
        [currentArticles, onArticleEnter, onArticleLeave],
    );
    const scrollCompletionRefs = useMemo(
        () =>
            currentArticles.map((currentArticle) => (ref) => {
                scrollCompletionRef.current[currentArticle.id] = ref;
            }),
        [currentArticles],
    );

    // Render nothing is not article is loaded
    if (currentArticles.length === 0 || article === null) {
        return null;
    }

    const { sections = [] } = site;
    const videosSection = sections.find((it) => it.id === 'videos');
    const currentIndex = currentArticles.findIndex((it) => it.id === article.id);
    const lastIndex = currentArticles.length - 1;
    const lastArticle = currentArticles[lastIndex];
    const { metadata: lastArticleMetadata } = lastArticle || {};
    const { color: lastArticleMetadataColor } = lastArticleMetadata || {};
    const { metadata: articleMetadata } = article || {};
    const { locations: articleLocations = [] } = articleMetadata || {};
    const lastIsDark = lastArticle.type === 'video';
    const isEmbed = article.type === 'video' || article.type === 'micromag';
    const isFullscreen = isEmbed && siteId === 'reader';
    const articleHasLocations = articleLocations !== null && articleLocations.length > 0;

    const { id: lastArticleId = null } = lastArticle || {};
    const disableInfiniteScroll = lastArticleId === '452257';

    const FeaturedSectionComponent = getComponentFromName(
        FeaturedSectionComponents,
        featuredSectionComponent,
    );

    return (
        <main
            className={classNames([
                styles.container,
                {
                    [styles.isFullscreen]: isFullscreen,
                },
            ])}
        >
            {!isDisabled ? (
                <ArticleMeta
                    article={article}
                    articleIndex={currentIndex}
                    onChange={onMetadataChange}
                />
            ) : null}
            {currentArticles.map((currentArticle, index) => {
                const {
                    id,
                    type,
                    url,
                    metadata: { commentsCount = 0, color = null } = {},
                } = currentArticle;
                const { onEnter = null, onLeave = null } = articlesDetectorProps[index] || {};
                return (
                    <div
                        key={`article-${id}`}
                        id={`article-${id}`}
                        ref={scrollCompletionRefs[index]}
                    >
                        <AdsTargetingProvider page={currentArticle}>
                            <Detector onEnter={onEnter} onLeave={onLeave}>
                                <Article
                                    article={currentArticle}
                                    layoutIsSmall={layoutIsSmall}
                                    className={styles.article}
                                    adsDisabled={currentArticle.id !== article.id}
                                    infiniteScrollIndex={index}
                                    withoutTopAds={index > 0 || withoutTopAds}
                                    withoutAds={withoutAds}
                                    withoutAddons={withoutAddons}
                                    withoutHeaderBackground={withoutHeaderBackground}
                                    withoutHeaderColoredBackground={withoutHeaderColoredBackground}
                                    hideCategory={hideCategory}
                                    withoutAuthorLink={withoutAuthorLink}
                                    isReader={isReader}
                                />

                                {type === 'video' &&
                                fromCollection === null &&
                                siteId !== 'reader' ? (
                                    <SectionRow
                                        section={videosSection}
                                        query={articlesRowQuery[index]}
                                        title={
                                            <FormattedMessage
                                                defaultMessage="Dernières vidéos"
                                                description="Block title"
                                            />
                                        }
                                        moreLabel={
                                            <FormattedMessage
                                                defaultMessage="Voir plus de vidéos"
                                                description="Button label"
                                            />
                                        }
                                        loadingLabel={
                                            <FormattedMessage
                                                defaultMessage="Chargement des vidéos..."
                                                description="Loading label"
                                            />
                                        }
                                        isFullWidth
                                        isCarousel={layoutIsSmall}
                                    />
                                ) : null}

                                {fromCollection !== null && siteId !== 'reader' ? (
                                    <CollectionRow
                                        collectionSlug={fromCollection}
                                        query={articlesRowQuery[index]}
                                        isFullWidth
                                        isCarousel={layoutIsSmall}
                                    />
                                ) : null}
                                {!withoutComments ? (
                                    <CommentsSection
                                        className={styles.comments}
                                        url={`https://${
                                            typeof window !== 'undefined'
                                                ? window.location.host
                                                : 'urbania.ca'
                                        }${url}`}
                                        count={commentsCount}
                                        backgroundColor={color}
                                    />
                                ) : null}
                                {FeaturedSectionComponent !== null && index === 0 ? (
                                    <FeaturedSectionComponent />
                                ) : null}
                                {!withoutAds ? <Ad slot="fullwidth" /> : null}
                            </Detector>
                        </AdsTargetingProvider>
                    </div>
                );
            })}
            {loadedArticles.length < maxLoadedArticles ? (
                <div
                    className={styles.more}
                    style={{
                        backgroundColor: lastIsDark
                            ? darkBackgroundColor
                            : lastArticleMetadataColor,
                    }}
                >
                    <Detector
                        onEnter={loadNextArticle}
                        disabled={loadingNext || scrolling || disableInfiniteScroll}
                    >
                        {!disableInfiniteScroll ? (
                            <More
                                loading={loadingNext}
                                onClick={loadNextArticle}
                                isDark={lastIsDark}
                                color={lastArticleMetadataColor}
                                label={
                                    <FormattedMessage
                                        defaultMessage="Charger le prochain article"
                                        description="Button label"
                                    />
                                }
                                loadingLabel={
                                    <FormattedMessage
                                        defaultMessage="Chargement du prochain article..."
                                        description="Loading label"
                                    />
                                }
                                disabled={loadingNext}
                            />
                        ) : null}
                    </Detector>
                </div>
            ) : null}
            {shouldDisplayMapButton ? (
                <ViewMapButton
                    href={generateUrl('maps')}
                    onClick={onClickViewMap}
                    className={classNames([
                        styles.viewMapButton,
                        {
                            [styles.visible]: articleHasLocations,
                        },
                    ])}
                />
            ) : null}
        </main>
    );
}

ArticlePage.propTypes = propTypes;
ArticlePage.defaultProps = defaultProps;

const WithThemeContainer = withTheme(ArticlePage, ({ darkBackgroundColor }) => ({
    darkBackgroundColor,
}));
WithThemeContainer.displayName = 'ArticlePage';

export default WithThemeContainer;
