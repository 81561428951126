/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import ContestForm from '../forms/contests/DefaultContest';

import styles from '../../../styles/blocks/contest.module.scss';

const propTypes = {
    contest: AppPropTypes.contest,
    withMaxWidth: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    withMaxWidth: false,
    className: null,
};

const ContestBlock = ({ contest, withMaxWidth, className }) => {
    const {
        id: contestId,
        title = null,
        isStarted = false,
        isComing = false,
        isEnded = false,
        startAt = null,
        endAt = null,
        theme = null,
        success = null,
    } = contest;
    const startAtDate = useMemo(() => parseISO(startAt), [startAt]);
    const endAtDate = useMemo(() => parseISO(endAt), [startAt]);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles.withMaxWidth]: withMaxWidth,
                },
            ])}
        >
            <div className={styles.inner}>
                {isStarted ? (
                    <ContestForm
                        contest={contest}
                        success={
                            <div className={styles.success}>
                                {success || (
                                    <FormattedMessage defaultMessage="Vous êtes inscrit·e au concours!" />
                                )}
                            </div>
                        }
                        withEmail
                    />
                ) : null}
                {isComing || isEnded ? (
                    <div className={styles.wait}>
                        <p>
                            {isComing ? (
                                <FormattedMessage
                                    defaultMessage="Le <strong>{title}</strong> commence le <strong>{date}</strong>."
                                    description="Contest wait message"
                                    values={{
                                        title,
                                        date: (
                                            <FormattedDate
                                                value={startAtDate}
                                                weekday="long"
                                                day="numeric"
                                                month="long"
                                            />
                                        ),
                                    }}
                                />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="Le <strong>{title}</strong> est terminé depuis le {date}."
                                    description="Contest wait message"
                                    values={{
                                        title,
                                        date: (
                                            <FormattedDate
                                                value={endAtDate}
                                                weekday="long"
                                                day="numeric"
                                                month="long"
                                            />
                                        ),
                                    }}
                                />
                            )}
                        </p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

ContestBlock.propTypes = propTypes;
ContestBlock.defaultProps = defaultProps;

export default ContestBlock;
