/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import useKeyboardKeys, { KEYS } from '../../hooks/useKeyboardKeys';

import ModalSafe from '../partials/ModalSafe';

import styles from '../../../styles/popups/modal.module.scss';

const propTypes = {
    children: PropTypes.node,
    containerRef: PropTypes.shape({}),
    innerRef: PropTypes.shape({}),
    opened: PropTypes.bool,
    setOpened: PropTypes.bool,
    onClickSafe: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    containerRef: null,
    innerRef: null,
    onClickSafe: null,
    onClosed: null,
    children: null,
    opened: false,
    setOpened: false,
    className: null,
};

function Modal({
    className,
    opened,
    setOpened,
    containerRef,
    innerRef,
    onClickSafe,
    onClosed,
    children,
}) {
    const onTransitionEnd = useCallback(() => {
        if (!opened && onClosed !== null) {
            onClosed();
        }
    }, [opened, onClosed]);
    const onClickInner = useCallback(
        (e) => {
            if (e.currentTarget === e.target && onClickSafe !== null) {
                onClickSafe(e);
            }
        },
        [onClickSafe],
    );

    const onRequestClose = useCallback(() => {
        if (opened && setOpened !== null) {
            setOpened(false);
        }
    }, [opened, setOpened]);

    useKeyboardKeys({
        [KEYS.ESCAPE]: onRequestClose,
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened === true,
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
            onTransitionEnd={onTransitionEnd}
        >
            <ModalSafe className={styles.safe} onClick={onClickSafe} />
            <div className={styles.inner} onClick={onClickInner} ref={innerRef}>
                {children}
            </div>
        </div>
    );
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <Modal {...props} containerRef={ref} />);
