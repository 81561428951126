import { getObserver, windowEventsManager, useWindowSize } from '@folklore/hooks';
import isArray from 'lodash/isArray';
import { useState, useMemo, useEffect, useRef } from 'react';

import { getElementsBounds } from '../lib/utils';

const useScrollCompletion = (ids, { triggers = [0, 0.5, 1.0], onTrigger = null } = {}) => {
    const refInitialValue = useMemo(() => (isArray(ids) ? {} : null), [ids]);
    const elementsRef = useRef(refInitialValue);
    const [elementsBounds, setElementsBounds] = useState({});
    // const [elementsTriggers, setElementsTriggers] = useState({});
    const elementsTriggersRef = useRef({});
    const { height } = useWindowSize();

    useEffect(() => {
        const elements = isArray(ids) ? elementsRef.current : { [ids]: elementsRef.current };
        const { subscribe, unsubscribe } = getObserver(ResizeObserver);
        let currentElementsBounds = {
            ...Object.keys(elements).reduce(
                (map, id) => ({
                    ...map,
                    [id]: null,
                }),
                {},
            ),
            ...elementsBounds,
        };
        Object.keys(elements).forEach((id) => {
            const element = elements[id] || null;
            if (element !== null) {
                subscribe(element, () => {
                    currentElementsBounds = {
                        ...currentElementsBounds,
                        [id]: getElementsBounds(element),
                    };
                    setElementsBounds(currentElementsBounds);
                });
            }
        });
        return () => {
            Object.keys(elements).forEach((id) => {
                const element = elements[id] || null;
                if (element !== null) {
                    unsubscribe(element);
                }
            });
        };
    }, [ids, setElementsBounds]);

    useEffect(() => {
        if (windowEventsManager === null) {
            return () => {};
        }
        const onScroll = () => {
            const { current: elementsTriggers } = elementsTriggersRef;
            const screenBottom = height + (window.scrollY || 0);
            const progresses = Object.keys(elementsBounds).reduce((map, id) => {
                const bounds = elementsBounds[id];
                const isInBound = bounds !== null && screenBottom > bounds.top;
                if (!isInBound) {
                    return map;
                }
                return {
                    ...map,
                    [id]: (screenBottom - bounds.top) / bounds.height,
                };
            }, {});
            const newElementsTriggers = Object.keys(progresses).reduce((map, id) => {
                const progress = progresses[id];
                const newCompletions = triggers
                    .filter((it) => progress > it)
                    .filter(
                        (it) =>
                            (elementsTriggers[id] || null) === null ||
                            elementsTriggers[id].indexOf(it) === -1,
                    );
                return newCompletions.length > 0
                    ? {
                          ...map,
                          [id]: newCompletions,
                      }
                    : map;
            }, null);
            if (newElementsTriggers !== null) {
                elementsTriggersRef.current = {
                    ...elementsTriggers,
                    ...Object.keys(newElementsTriggers).reduce(
                        (map, id) => ({
                            ...map,
                            [id]: [...(elementsTriggers[id] || []), ...newElementsTriggers[id]],
                        }),
                        {},
                    ),
                };
                if (onTrigger !== null) {
                    Object.keys(newElementsTriggers).forEach((id) => {
                        (newElementsTriggers[id] || []).forEach((trigger) =>
                            onTrigger(id, trigger),
                        );
                    });
                }
            }
        };

        const { current: elementsTriggers } = elementsTriggersRef;
        windowEventsManager.subscribe('scroll', onScroll);
        if (Object.keys(elementsTriggers).length === 0) {
            onScroll();
        }

        return () => {
            windowEventsManager.unsubscribe('scroll', onScroll);
        };
    }, [triggers, elementsBounds, height, onTrigger]);

    return {
        ref: elementsRef,
    };
};

export default useScrollCompletion;
