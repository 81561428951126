import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import useSubscribed from '../../hooks/useSubscribed';

import EvilZuckerbergLink from '../partials/EvilZuckerbergLink';
import SubscriptionBox from './SubscriptionBox';
import SubscriptionButton from './SubscriptionButton';
import SubscriptionIcon from './SubscriptionIcon';
import SubscriptionText from './SubscriptionText';
import SubscriptionWizardForm from './SubscriptionWizardForm';

import styles from '../../../styles/subscription/section.module.scss';

const propTypes = {
    variant: PropTypes.string,
    subscription: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    modalCta: PropTypes.string,
    theme: PropTypes.string,
    icon: PropTypes.string,
    instagram: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    variant: null,
    subscription: null,
    title: 'Abonnez-vous à l’infolettre URBANIA',
    description: 'Vous êtes spécial, notre infolettre l’est aussi',
    modalCta: null,
    theme: 'urbania',
    icon: 'urbania',
    instagram: null,
    className: null,
};

function SubscriptionSection({
    variant,
    subscription,
    title,
    description,
    modalCta,
    icon,
    instagram,
    theme,
    className,
}) {
    const [open, setOpen] = useState(false);

    const onOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const subscribed = useSubscribed(subscription);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <SubscriptionBox theme={theme} className={styles.box}>
                <div className={styles.inner}>
                    <SubscriptionIcon
                        name={icon}
                        subscribed={subscribed}
                        placement="section"
                        className={classNames([
                            styles.icon,
                            {
                                [styles[icon]]: typeof styles[icon] !== 'undefined',
                            },
                        ])}
                        micromagDemoClassName={styles.micromag}
                    />
                    <div className={styles.content}>
                        <SubscriptionText
                            title={title}
                            description={description}
                            theme={theme}
                            className={styles.text}
                            titleClassName={styles.title}
                            contentClassName={styles.textContent}
                            descriptionClassName={styles.description}
                        />
                        <div className={styles.action}>
                            {instagram !== null ? (
                                <SubscriptionButton
                                    theme={theme}
                                    href={`https://instagram.com/${instagram}`}
                                    external
                                >
                                    <FormattedMessage
                                        defaultMessage="Suivez-nous sur instagram"
                                        description="Button label"
                                    />
                                </SubscriptionButton>
                            ) : (
                                <SubscriptionWizardForm
                                    theme={theme}
                                    subscription={subscription}
                                    source={variant !== null ? `section:${variant}` : 'section'}
                                    buttonIcon={<div className={styles.fieldIcon}>→</div>}
                                    className={styles.field}
                                    onFieldFocus={onOpen}
                                    onDismiss={onClose}
                                    withModal
                                    withFixedSize
                                    withChildren={open}
                                >
                                    <p className={styles.cta}>{modalCta || title}</p>
                                </SubscriptionWizardForm>
                            )}
                        </div>
                        {theme === 'evil' ? (
                            <EvilZuckerbergLink className={styles.evil} onClose={onClose} />
                        ) : null}
                    </div>
                </div>
            </SubscriptionBox>
        </div>
    );
}

SubscriptionSection.propTypes = propTypes;
SubscriptionSection.defaultProps = defaultProps;

export default SubscriptionSection;
