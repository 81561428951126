/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { useCollection } from '../../hooks/usePage';
import * as AppPropTypes from '../../lib/PropTypes';
import { getImageAtSize } from '../../lib/utils';

import { useUrlGenerator } from '@folklore/routes';
import { withTheme } from '../../contexts/SiteContext';
import ArticlesRow from './ArticlesRow';

import styles from '../../../styles/lists/collection-row.module.scss';

const propTypes = {
    collection: AppPropTypes.collection,
    collectionSlug: PropTypes.string,
    layout: PropTypes.oneOf(['default', 'half', 'small', 'full']),
    className: PropTypes.string,
    headerClassName: PropTypes.string,
};

const defaultProps = {
    collection: null,
    collectionSlug: null,
    layout: 'default',
    className: null,
    headerClassName: null,
};

const CollectionRowList = ({
    collection,
    collectionSlug,
    layout,
    className,
    headerClassName,
    ...props
}) => {
    const [loadedCollection, setLoadedCollection] = useState(null);
    const finalCollection = collection || loadedCollection;
    useCollection({
        slug: collectionSlug,
        collection,
        onLoad: (newCollection) => setLoadedCollection(newCollection),
    });
    const generateUrl = useUrlGenerator();
    const articlesUrl = useMemo(
        () =>
            finalCollection !== null
                ? generateUrl('collections.list.json', {
                      collection: finalCollection.slug,
                  })
                : null,
        [generateUrl, finalCollection],
    );
    const {
        url = null,
        title = null,
        theme = null,
        image = null,
        metadata: { sponsors = null } = {},
    } = finalCollection || {};
    const imageAtSize = getImageAtSize(image, 'large');

    const componentProps = useMemo(
        () => ({
            fromCollection: finalCollection,
            linkUnderlineColor: theme === '20ans' ? '#cc00cc' : null,
            textColor: theme === '20ans' ? '#fff' : null,
            playButtonColor: theme === '20ans' ? '#cc00cc' : null,
            strokeButtonColor: theme === '20ans' ? '#fff' : null,
            presentedByBackgroundColor: theme === '20ans' ? '#cc00cc' : null,
            presentedByColor: theme === '20ans' ? '#fff' : null,
            className: theme === '20ans' ? styles.articleCard20ans : null,
        }),
        [finalCollection],
    );

    return (
        <ArticlesRow
            url={articlesUrl}
            link={url}
            title={title}
            presentedBy={sponsors !== null && sponsors.length > 0 ? sponsors : null}
            layout={layout}
            componentProps={componentProps}
            className={classNames([
                styles.container,
                styles[layout],
                {
                    [styles[theme]]: theme !== null,
                    [className]: className !== null,
                },
            ])}
            headerClassName={classNames([
                styles.header,
                {
                    [headerClassName]: headerClassName !== null,
                },
            ])}
            style={{
                backgroundImage: imageAtSize !== null ? `url("${imageAtSize.url}")` : null,
            }}
            {...props}
        />
    );
};

CollectionRowList.propTypes = propTypes;
CollectionRowList.defaultProps = defaultProps;

const WithThemeContainer = withTheme(CollectionRowList, ({ darkBackgroundColor }) => ({
    darkBackgroundColor,
}));

export default WithThemeContainer;
