import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const WithIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="7.6px"
        height="7.6px"
        viewBox="0 0 7.6 7.6"
        className={className}
        xmlSpace="preserve"
    >
        <rect
            x="-0.7"
            y="3"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 3.8013 -1.5771)"
            fill={color}
            width="9"
            height="1.7"
        />
        <rect
            x="-0.7"
            y="3"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 9.1632 3.7955)"
            fill={color}
            width="9"
            height="1.7"
        />
    </svg>
);

WithIcon.propTypes = propTypes;
WithIcon.defaultProps = defaultProps;

export default WithIcon;
