/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

import NewsletterPopup from './Newsletter';

const propTypes = {
};

const defaultProps = {

};

const NewsletterForm = (props) => (
    <NewsletterPopup type="modal" {...props} />
);

NewsletterForm.propTypes = propTypes;
NewsletterForm.defaultProps = defaultProps;

export default NewsletterForm;
