import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function MapPinIcon({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.05 19.74" className={className}>
            <path
                fill={color}
                d="M12.8,4.14c-.4-1.34-1.28-2.48-2.47-3.2C9.13,.23,7.75-.09,6.36,.03l.32-.03c-.55,0-1.1,.11-1.61,.31-1.01,.28-1.97,.69-2.88,1.2C1.27,2.03,.57,2.86,.2,3.85-.02,4.69-.06,5.56,.08,6.42c.13,2.14,.74,4.21,1.78,6.08,.56,.9,1.28,1.69,1.89,2.56,.7,1.01,1.25,2.12,1.63,3.29,.2,.63,.54,1.42,1.2,1.39,.48-.03,.79-.5,1.03-.92l3.79-6.85c.49-.82,.9-1.69,1.22-2.59,.32-1.07,.46-2.19,.41-3.31,0-.65-.08-1.3-.24-1.93Zm-4.4,5.33c-1.32,.79-2.99,.65-4.15-.36l-.39-.55c-.54-.71-.63-1.67-.22-2.47,.37-1.06,1.08-1.98,2.02-2.6,.29-.19,.63-.28,.98-.27,.29,.04,.57,.15,.81,.3,.43,.23,.85,.46,1.26,.71,.26,.14,.51,.33,.72,.54,.21,.24,.36,.53,.44,.84,.41,1.47-.19,3.03-1.47,3.86Z"
            />
        </svg>
    );
}

MapPinIcon.propTypes = propTypes;
MapPinIcon.defaultProps = defaultProps;

export default MapPinIcon;
