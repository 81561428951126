/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/partials/modal-safe.module.scss';

const propTypes = {
    opacity: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    opacity: null,
    onClick: null,
    className: null,
};

const ModalSafe = ({ opacity, onClick, className }) => (
    <button
        type="button"
        onClick={onClick}
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        style={{
            opacity,
        }}
    />
);

ModalSafe.propTypes = propTypes;
ModalSafe.defaultProps = defaultProps;

export default ModalSafe;
