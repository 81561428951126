/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '../../lib/utils';
import { withTheme } from '../../contexts/SiteContext';
import * as BrandComponents from '../icons/logos';
import Menu from '../menus/Menu';
import SiteSponsor from './SiteSponsor';

import styles from '../../../styles/partials/footer.module.scss';

const propTypes = {
    menus: PropTypes.arrayOf(AppPropTypes.menu),
    textColor: AppPropTypes.color,
    textStyle: AppPropTypes.textStyle,
    backgroundColor: AppPropTypes.color,
    sponsorColor: AppPropTypes.color,
    brandColor: AppPropTypes.color,
    brandComponent: PropTypes.string,
    withoutSponsor: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    menus: [],
    textColor: '#fff',
    textStyle: null,
    backgroundColor: '#000',
    sponsorColor: '#999',
    brandColor: '#999',
    brandComponent: null,
    withoutSponsor: false,
    className: null,
};

const Footer = ({
    menus,
    textColor,
    textStyle,
    backgroundColor,
    sponsorColor,
    brandColor,
    brandComponent,
    withoutSponsor,
    className,
}) => {
    const BrandComponent = getComponentFromName(BrandComponents, brandComponent, null);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withoutSponsor]: withoutSponsor,
                    [className]: className !== null,
                },
            ])}
            style={{ ...textStyle, color: textColor, backgroundColor }}
        >
            <div className={styles.inner}>
                {BrandComponent !== null ? (
                    <div className={styles.brand} style={{ color: brandColor }}>
                        <BrandComponent className={styles.logo} />
                    </div>
                ) : null}
                <div className={styles.lists}>
                    {menus.map(({ items }, index) => (
                        <div key={`list-${index}`} className={styles.list}>
                            <Menu
                                items={items}
                                className={styles.menu}
                                subMenuClassName={styles.subMenu}
                                itemClassName={styles.item}
                                hasSubMenuClassName={styles.hasSubMenu}
                                openedClassName={styles.opened}
                                linkClassName={styles.link}
                                labelClassName={styles.label}
                            />
                        </div>
                    ))}
                </div>
                {!withoutSponsor ? (
                    <div className={styles.sponsor}>
                        <SiteSponsor color={sponsorColor} monochrome />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default withTheme(
    React.memo(Footer),
    ({
        fonts: { otherTextStyle } = {},
        footer: {
            textColor,
            backgroundColor,
            sponsorColor,
            brandColor,
            brandComponent,
            withoutSponsor = false,
        } = {},
    }) => ({
        textStyle: otherTextStyle,
        textColor,
        backgroundColor,
        sponsorColor,
        brandColor,
        brandComponent,
        withoutSponsor,
    }),
);
