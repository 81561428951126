import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useTrackOnClickLink } from '../../hooks/useTracking';
import { getComponentFromName } from '../../lib/utils';

import { withTheme } from '../../contexts/SiteContext';
import BurgerButton from '../buttons/Burger';
import * as Logos from '../icons/logos';
import Link from '../partials/Link';

import styles from '../../../styles/headers/header-logo.module.scss';

const propTypes = {
    href: PropTypes.string,
    component: PropTypes.string,
    color: PropTypes.string,
    toggleMenu: PropTypes.func,
    className: PropTypes.string,
    logoClassName: PropTypes.string,
    linkClassName: PropTypes.string,
};

const defaultProps = {
    href: null,
    component: 'urbania',
    color: null,
    toggleMenu: null,
    className: null,
    logoClassName: null,
    linkClassName: null,
};

function HeaderLogo({
    href,
    component,
    color,
    toggleMenu,
    className,
    logoClassName,
    linkClassName,
}) {
    const LogoComponent = getComponentFromName(Logos, component, 'urbania');
    const onClickLogo = useTrackOnClickLink('click_header_logo');

    const logo =
        LogoComponent !== null ? (
            <LogoComponent
                className={classNames([
                    styles.logo,
                    {
                        [logoClassName]: logoClassName !== null,
                    },
                ])}
            />
        ) : null;

    const logoLink =
        href !== null ? (
            <Link
                href={href}
                className={classNames([
                    styles.link,
                    {
                        [linkClassName]: linkClassName !== null,
                    },
                ])}
                onClick={onClickLogo}
            >
                {logo}
            </Link>
        ) : (
            logo
        );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ color }}
        >
            {logoLink}
            <BurgerButton className={styles.button} onClick={toggleMenu} color="currentColor" />
        </div>
    );
}

HeaderLogo.propTypes = propTypes;
HeaderLogo.defaultProps = defaultProps;

const WithThemeComponent = withTheme(HeaderLogo, ({ header: { logoComponent } = {} }) => ({
    component: logoComponent,
}));
export default WithThemeComponent;
