import { useResizeObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'wouter';

// import useFonts from '../../hooks/useFonts';
import useHeaderAd from '../../hooks/useHeaderAd';
import useLayoutMainMenu from '../../hooks/useLayoutMainMenu';
import useLayoutScroll from '../../hooks/useLayoutScroll';
import { useTrackEvent } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';

import {
    useLayoutOptions,
    useLayoutScrollDisabled,
    useLayoutSize,
    useLayoutHeader,
} from '../../contexts/LayoutContext';
import { useSite, withTheme } from '../../contexts/SiteContext';
import Header from '../headers/Header';
import MainMenu from '../menus/MainMenu';
import Sites from '../menus/Sites';
import AdSplash from '../partials/AdSplash';
import Footer from '../partials/Footer';
import LoadingBar from '../partials/LoadingBar';
import ModalSafe from '../partials/ModalSafe';
import Popup from '../popups/Popup';
import ContactSection from '../sections/Contact';
import SubscriptionSection from '../sections/Subscription';
import defaultFonts from './fonts';

import styles from '../../../styles/layouts/main.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    pageIsLoaded: PropTypes.bool,
    page: AppPropTypes.page,
    fonts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
    loadingColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
};

const defaultProps = {
    pageIsLoaded: false,
    page: null,
    loadingColor: null,
    backgroundColor: null,
    textColor: null,
    fonts: defaultFonts,
};

function MainLayout({
    fonts,
    children,
    pageIsLoaded,
    page,
    loadingColor,
    textColor,
    backgroundColor,
}) {
    const site = useSite();
    const [location] = useLocation();
    const trackEvent = useTrackEvent();
    const scrollDisabled = useLayoutScrollDisabled();
    const { headerHidden = false } = useLayoutHeader();

    const { content_min_height_viewport: contentMinHeightViewport = true } = page || {};
    const { ads = {}, id: siteId = null } = site || {};
    const { headerSplash = {} } = ads || {};
    const { name = 'ad', enabled = false, url: adUrl = '' } = headerSplash;

    // @TODO Move the custom fonts to site
    // eslint-disable-next-line
    const finalFonts = useMemo(
        () =>
            siteId === 'quatre95'
                ? fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Apercu')
                : fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Maax'),
        [fonts, siteId],
    );
    // useFonts(finalFonts);

    const size = useLayoutSize();
    const { width } = size;

    // Header height
    const {
        ref: refHeader,
        entry: { contentRect: headerContentRect },
    } = useResizeObserver();

    const { height: headerHeight = siteId === 'quatre95' ? 70 : 80 } = headerContentRect || {};
    useEffect(() => {
        document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`);
    }, [headerHeight]);

    const {
        menuOpened,
        closeMenu,
        openMenu,
        onClickLightbox,
        toggleMenu,
        sectionsMenu,
        pagesMenus,
        footerMenu,
    } = useLayoutMainMenu(location);

    // Header ads
    const [adSplashHeight, setAdSplashHeight] = useState(0);

    const { viewed, setViewed } = useHeaderAd({ key: name });
    const splashEnabled = useMemo(() => !viewed && enabled, [viewed, enabled]);
    const headerAdEnabled = useMemo(() => width < 768 && splashEnabled, [width, splashEnabled]);

    const [showSplash, setShowSplash] = useState(splashEnabled);
    const adIsDisplayed = useMemo(
        () => showSplash && headerAdEnabled,
        [showSplash, headerAdEnabled],
    );

    const isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);

    const {
        headerTransparent,
        headerTextColor = null,
        headerLogoComponent = null,
    } = useLayoutOptions();

    const headerRatio = useMemo(() => (siteId === 'dehors' ? 2 : 4), [siteId]);

    const { headerSticky = false, adsShouldBeRemoved = false } = useLayoutScroll(
        adSplashHeight,
        headerHeight,
        headerRatio,
        showSplash,
        adIsDisplayed,
    );

    const removeAd = useCallback(() => {
        if (adIsDisplayed) {
            trackEvent('Ads', 'close', name, adUrl);
            setViewed();
        }
        // To avoid a page jump on safari
        if (isSafari && window.scroll) {
            window.scroll({
                top: 0,
            });
        }
        setShowSplash(false);
    }, [adIsDisplayed, trackEvent, setShowSplash, setViewed, name, adUrl, isSafari]);

    useEffect(() => {
        if (adsShouldBeRemoved) {
            removeAd();
        }
    }, [adsShouldBeRemoved, removeAd]);

    return (
        <div
            className={classNames([
                styles.container,
                siteId,
                {
                    [styles.scrollDisabled]: scrollDisabled,
                },
            ])}
            style={{
                backgroundColor,
                color: textColor,
            }}
        >
            <div className={styles.loading}>
                <LoadingBar loading={!pageIsLoaded} loaded={pageIsLoaded} color={loadingColor} />
            </div>
            <div className={styles.adSplash}>
                {showSplash && headerAdEnabled ? (
                    <AdSplash
                        data={headerSplash}
                        toggleVisible={removeAd}
                        onHeightChange={setAdSplashHeight}
                    />
                ) : null}
            </div>
            <Header
                ref={refHeader}
                sticky={headerSticky}
                stickyGapHeight={headerHeight}
                menuOpened={menuOpened}
                openMenu={openMenu}
                closeMenu={closeMenu}
                toggleMenu={toggleMenu}
                transparent={headerTransparent && !headerSticky}
                logoComponent={!headerSticky ? headerLogoComponent : undefined}
                textColor={!headerSticky ? headerTextColor : undefined}
                className={classNames([
                    styles.header,
                    {
                        [styles.sticky]: headerSticky,
                        [styles.headerHidden]: headerHidden,
                    },
                ])}
                page={page}
            />
            <MainMenu
                sectionsMenu={sectionsMenu}
                pagesMenus={pagesMenus.items}
                opened={menuOpened}
                openMenu={openMenu}
                closeMenu={closeMenu}
                toggleMenu={toggleMenu}
                className={classNames([
                    styles.mainMenu,
                    {
                        [styles.opened]: menuOpened,
                    },
                ])}
            />
            <div className={styles.inner}>
                <div
                    className={classNames([
                        styles.content,
                        {
                            [styles.minHeightViewport]: contentMinHeightViewport,
                        },
                    ])}
                >
                    {children}
                </div>
            </div>
            {siteId === 'universities' ? <ContactSection /> : null}
            {siteId !== 'universities' &&
            siteId !== 'reader' &&
            page !== null &&
            page.handle !== 'subscription' &&
            page.type !== 'contest' ? (
                <SubscriptionSection />
            ) : null}
            {siteId !== 'reader' ? (
                <>
                    <Footer menus={footerMenu.items} />
                    <Sites />
                    <Popup />
                </>
            ) : null}

            <ModalSafe
                onClick={onClickLightbox}
                className={classNames([
                    styles.mainMenuBlock,
                    {
                        [styles.opened]: menuOpened,
                    },
                ])}
            />
        </div>
    );
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    MainLayout,
    ({ textColor, backgroundColor, header: { loadingColor } = {} }) => ({
        loadingColor,
        textColor,
        backgroundColor,
    }),
);

export default WithThemeContainer;
