/* eslint-disable react/jsx-props-no-spreading */
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

const propTypes = {
    underlineColor: AppPropTypes.color,
    borderBottom: PropTypes.bool,
    selector: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    linkSelector: PropTypes.string,
};

const defaultProps = {
    underlineColor: null,
    borderBottom: false,
    selector: null,
    linkSelector: 'a',
};

const LinkStyle = ({ underlineColor, borderBottom, selector, linkSelector }) => (
    <style type="text/css">{`
        ${(isArray(selector) ? selector : [selector])
            .map((sel) => [sel, linkSelector].filter((it) => it !== null).join(' '))
            .join(',')} {
            ${
                borderBottom
                    ? `border-bottom-color: ${underlineColor}`
                    : `
                background-image: -ms-linear-gradient(0deg, ${underlineColor} 0, ${underlineColor});
                background-image: -webkit-linear-gradient(0deg, ${underlineColor} 0, ${underlineColor});
                background-image: -moz-linear-gradient(0deg, ${underlineColor} 0, ${underlineColor});
                background-image: -o-linear-gradient(0deg, ${underlineColor} 0, ${underlineColor});
                background-image: linear-gradient(0deg, ${underlineColor} 0, ${underlineColor});
            `
            }
        }
    `}</style>
);

LinkStyle.propTypes = propTypes;
LinkStyle.defaultProps = defaultProps;

export default LinkStyle;
