/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { withSite } from '../../contexts/SiteContext';
import { withPage } from '../../contexts/PageContext';
import { hexToRGB } from '../../lib/utils';
import { withArticleTheme } from '../../contexts/ArticleThemeContext';
import GuideIcon from '../icons/logos/UniversitiesGuideCircle';
import LinkStyle from '../partials/LinkStyle';

import styles from '../../../styles/blocks/contact.module.scss';

const propTypes = {
    site: AppPropTypes.site.isRequired,
    page: AppPropTypes.page.isRequired,
    color: AppPropTypes.color,
    withMaxWidth: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: null,
    withMaxWidth: false,
    className: null,
};

const ContactBlock = ({
    site,
    page: {
        metadata: { section = null },
    },
    color,
    withMaxWidth,
    className,
}) => {
    const { university = null } = section || {};
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles.withMaxWidth]: withMaxWidth,
                },
            ])}
            style={{ color }}
        >
            <LinkStyle selector={`.${styles.title}`} underlineColor={hexToRGB(color, 0.8)} />
            <div className={styles.inner}>
                <GuideIcon className={styles.icon} color={color} />
                <div className={styles.textBox}>
                    <h4 className={styles.title}>
                        {university !== null ? (
                            <FormattedMessage
                                defaultMessage="Vous connaissez les secrets{br}les mieux gardés de {university}?{br}Écrivez-nous.{br}{email}"
                                description="Block title"
                                values={{
                                    email: <a href={`mailto:${site.email}`}>{site.email}</a>,
                                    university: university.shortName,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Vous connaissez les secrets{br}les mieux gardés de votre université?{br}Écrivez-nous.{br}{email}"
                                description="Block title"
                                values={{
                                    email: <a href={`mailto:${site.email}`}>{site.email}</a>,
                                }}
                            />
                        )}
                    </h4>
                </div>
            </div>
        </div>
    );
};

ContactBlock.propTypes = propTypes;
ContactBlock.defaultProps = defaultProps;

const WithSiteContainer = withSite(ContactBlock);
const WithPageContainer = withPage(WithSiteContainer);
export default withArticleTheme(WithPageContainer, ({ contactColor }) => ({
    color: contactColor,
}));
