import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const CaretIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        className={className}
        xmlSpace="preserve"
    >
        <path
            fill={color}
            d="M16.8,0H1.2C0.5,0,0,0.5,0,1.2v9.6C0,11.5,0.5,12,1.2,12H12l6,6v-7.2V6V1.2C18,0.5,17.5,0,16.8,0z"
        />
    </svg>
);

CaretIcon.propTypes = propTypes;
CaretIcon.defaultProps = defaultProps;

export default CaretIcon;
