/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

import NewsletterPopup from './Newsletter';

const propTypes = {
};

const defaultProps = {

};

const NewsletterBanner = (props) => (
    <NewsletterPopup type="banner" {...props} />
);

NewsletterBanner.propTypes = propTypes;
NewsletterBanner.defaultProps = defaultProps;

export default NewsletterBanner;
