/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import { withArticleTheme } from '../../contexts/ArticleThemeContext';
import { useSite } from '../../contexts/SiteContext';
import LocationCard from '../cards/LocationCard';

import styles from '../../../styles/blocks/locations.module.scss';

const propTypes = {
    locations: AppPropTypes.locations,
    color: PropTypes.string,
    textStyle: AppPropTypes.textStyle,
    withMaxWidth: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
};

const defaultProps = {
    locations: [],
    color: null,
    textStyle: null,
    withMaxWidth: false,
    className: null,
    innerClassName: null,
};

function LocationsBlock({ locations, withMaxWidth, textStyle, color, className, innerClassName }) {
    const { id: siteId } = useSite();
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withMaxWidth]: withMaxWidth,
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={classNames([
                    styles.inner,
                    { [innerClassName]: innerClassName !== null },
                ])}
            >
                <h4 className={styles.title} style={{ ...textStyle, color }}>
                    {siteId === 'dehors' ? (
                        <FormattedMessage
                            defaultMessage="Les lieux de ce contenu sont localisés sur la carte Dehors"
                            description="Block title"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Les lieux de ce contenu sont localisés sur notre carte"
                            description="Block title"
                        />
                    )}
                </h4>
                <ul className={styles.items}>
                    {locations.map((location) => (
                        <li key={`location-${location.id}`} className={styles.item}>
                            <LocationCard
                                item={location}
                                withIcon
                                withMapsButton
                                className={styles.card}
                                contentClassName={styles.content}
                                labelClassName={styles.label}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

LocationsBlock.propTypes = propTypes;
LocationsBlock.defaultProps = defaultProps;

export default withArticleTheme(LocationsBlock, ({ headingColor, headingTextStyle }) => ({
    color: headingColor,
    textStyle: headingTextStyle,
}));
