// eslint-disable-next-line import/prefer-default-export
import Loadable from 'react-loadable';

export const BncSurvey2020 = Loadable({
    loader: () => import('./BncSurvey2020').then(({ default: Component }) => Component),
    loading: () => null,
});

export const BncSurvey202106 = Loadable({
    loader: () => import('./BncSurvey202106').then(({ default: Component }) => Component),
    loading: () => null,
});

export const ContestQuatre95 = Loadable({
    loader: () => import('./ContestQuatre95').then(({ default: Component }) => Component),
    loading: () => null,
});

export const LairDallerTrailer2023Modal = Loadable({
    loader: () =>
        import('./LairDallerTrailer2023Modal').then(({ default: Component }) => Component),
    loading: () => null,
});

export const LairDallerTrailer2024Modal = Loadable({
    loader: () =>
        import('./LairDallerTrailer2024Modal').then(({ default: Component }) => Component),
    loading: () => null,
});

export const NewsletterQuatre95Modal = Loadable({
    loader: () => import('./NewsletterQuatre95Modal').then(({ default: Component }) => Component),
    loading: () => null,
});

export const NewsletterQuatre95Banner = Loadable({
    loader: () => import('./NewsletterQuatre95Banner').then(({ default: Component }) => Component),
    loading: () => null,
});

export { default as NewsletterBanner } from './NewsletterBanner';
export { default as NewsletterModal } from './NewsletterModal';
export { default as NewsletterModalForm } from './NewsletterModalForm';
export { default as SubscriptionModal } from './SubscriptionModal';
export { default as SubscriptionBanner } from './SubscriptionBanner';
