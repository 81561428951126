/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

// import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '../../lib/utils';

import { usePopup } from '../../contexts/PopupContext';
import * as Popups from './index';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function Popup({ className }) {
    const { popup, opened, close, props: popupProps = null } = usePopup();
    const PopupComponent = getComponentFromName(Popups, popup);
    const [popupContainer, setPopupContainer] = useState(null);

    useEffect(() => {
        const container = document.createElement('div');
        container.id = 'POPUP_CONTAINER';
        document.body.appendChild(container);
        setPopupContainer(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);

    return popupContainer !== null
        ? createPortal(
              opened && PopupComponent !== null ? (
                  <PopupComponent {...popupProps} className={className} closePopup={close} />
              ) : null,
              popupContainer,
          )
        : null;
}

Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;

export default Popup;
