/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from '../../contexts/SiteContext';
import { getComponentFromName } from '../../lib/utils';
import * as Menus from './main';

const propTypes = {
    component: PropTypes.string,
};

const defaultProps = {
    component: 'default',
};

function MainMenu({ component, ...props }) {
    const MainMenuComponent = getComponentFromName(Menus, component, 'default');
    return <MainMenuComponent {...props} />;
};

MainMenu.propTypes = propTypes;
MainMenu.defaultProps = defaultProps;

const WithThemeComponent = withTheme(React.memo(MainMenu), ({ mainMenu: { component } = {} }) => ({
    component,
}));

export default WithThemeComponent;
