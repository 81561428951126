import { useEffect, useCallback, useMemo } from 'react';

import { useMenuOpened, useCloseMenu, useOpenMenu } from '../contexts/LayoutContext';
import { useMenus } from '../contexts/SiteContext';
import { useTrackEvent } from './useTracking';

const useLayoutMainMenu = (location) => {
    const trackEvent = useTrackEvent();
    const menus = useMenus();
    const menuOpened = useMenuOpened();
    const closeMenu = useCloseMenu();
    const openMenu = useOpenMenu();
    useEffect(() => {
        if (menuOpened) {
            closeMenu();
        }
    }, [location, closeMenu]);

    const onClickLightbox = useCallback(() => closeMenu(), [closeMenu]);

    const toggleMenu = useCallback(() => {
        if (menuOpened) {
            trackEvent('Navigation', 'click_menu', 'close');
            closeMenu();
        } else {
            trackEvent('Navigation', 'click_menu', 'open');
            openMenu();
        }
    }, [menuOpened, openMenu, closeMenu, trackEvent]);

    const {
        sections: sectionsMenu,
        pages: pagesMenus,
        footer: footerMenu,
    } = useMemo(
        () => ({
            sections: menus.find((it) => it.id === 'header_sections'),
            pages: menus.find((it) => it.id === 'header_pages'),
            footer: menus.find((it) => it.id === 'footer'),
        }),
        [menus],
    );

    return {
        menuOpened,
        closeMenu,
        openMenu,
        onClickLightbox,
        toggleMenu,
        sectionsMenu,
        pagesMenus,
        footerMenu,
    };
};

export default useLayoutMainMenu;
