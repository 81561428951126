// import { useWindowScroll } from '@folklore/hooks';
import { useEffect, useMemo, useState, useCallback } from 'react';

const useLayoutScroll = (adSplashHeight, headerHeight, headerRatio, showSplash, adIsDisplayed) => {
    const [headerSticky, setHeaderSticky] = useState(false);
    const [adsShouldBeRemoved, setAdsShouldBeRemoved] = useState(false);
    const setScroll = useCallback(() => {
        const scrollY = window.scrollY || 0;
        const removeAds =
            adIsDisplayed &&
            adSplashHeight > 0 &&
            scrollY > 10 &&
            scrollY >= adSplashHeight + headerHeight / 4;
        setAdsShouldBeRemoved(removeAds);
        const sticky =
            showSplash && adSplashHeight > 0
                ? scrollY > adSplashHeight
                : scrollY > headerHeight / headerRatio;

        setHeaderSticky(sticky);
    }, [setHeaderSticky, setAdsShouldBeRemoved]);

    useEffect(() => {
        window.addEventListener('scroll', setScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', setScroll);
        };
    }, [setScroll]);

    const value = useMemo(
        () => ({ headerSticky, adsShouldBeRemoved }),
        [headerSticky, adsShouldBeRemoved],
    );

    return value;
};

export default useLayoutScroll;
