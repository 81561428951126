import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useSubscribed from '../../hooks/useSubscribed';

import Button from '../buttons/Button';
import CloseCircleButton from '../buttons/CloseCircle';
import SubscriptionBox from './SubscriptionBox';
import SubscriptionButton from './SubscriptionButton';
import SubscriptionForm from './SubscriptionForm';
import SubscriptionIcon from './SubscriptionIcon';
import SubscriptionText from './SubscriptionText';

// import SubscriptionWizardForm from './SubscriptionWizardForm';
import styles from '../../../styles/subscription/banner.module.scss';

const propTypes = {
    variant: PropTypes.string,
    subscription: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.string,
    icon: PropTypes.string,
    instagram: PropTypes.string,
    onClickClose: PropTypes.func,
    onComplete: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    variant: null,
    subscription: null,
    title: 'Abonnez-vous à l’infolettre URBANIA',
    description: 'Vous êtes spécial, notre infolettre l’est aussi',
    theme: 'dehorsGradient',
    icon: 'dehorsWeather',
    instagram: null,
    onClickClose: null,
    onComplete: null,
    className: null,
};

function SubscriptionBanner({
    variant,
    subscription,
    title,
    description,
    icon,
    instagram,
    theme,
    onClickClose,
    onComplete,
    className,
}) {
    const subscribed = useSubscribed(subscription);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[theme]]: theme !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <SubscriptionBox theme={theme} className={styles.box}>
                <div className={styles.inner}>
                    <Button
                        className={classNames([styles.closeButton, styles.arrow])}
                        onClick={onClickClose}
                    >
                        ▼
                    </Button>
                    <CloseCircleButton
                        onClick={onClickClose}
                        className={classNames([styles.closeButton, styles.cross])}
                    />
                    <SubscriptionIcon
                        name={icon}
                        className={styles.icon}
                        placement="banner"
                        subscribed={subscribed}
                        onClickClose={onClickClose}
                    />
                    <div className={styles.content}>
                        <SubscriptionText
                            title={title}
                            description={description}
                            theme={theme}
                            className={styles.text}
                            titleClassName={styles.title}
                            contentClassName={styles.textContent}
                            descriptionClassName={styles.description}
                        />
                        <div className={styles.action}>
                            {instagram !== null ? (
                                <SubscriptionButton
                                    theme={theme}
                                    href={`https://instagram.com/${instagram}`}
                                    external
                                >
                                    <FormattedMessage
                                        defaultMessage="Suivez-nous sur instagram"
                                        description="Button label"
                                    />
                                </SubscriptionButton>
                            ) : (
                                <SubscriptionForm
                                    subscription={subscription}
                                    theme={theme}
                                    source={variant !== null ? `banner:${variant}` : 'banner'}
                                    className={styles.field}
                                    onDismiss={onClickClose}
                                    onComplete={onComplete}
                                />

                                // <SubscriptionWizardForm
                                //     subscription={subscription}
                                //     theme={theme}
                                //     source={variant !== null ? `block:${variant}` : 'block'}
                                //     containerClassName={classNames([
                                //         styles.field,
                                //         // { [styles.open]: open },
                                //     ])}
                                //     // onFieldFocus={onOpen}
                                //     // onDismiss={onClose}
                                //     // withChildren={open}
                                //     withModal
                                //     withFixedSize
                                // >
                                //     <SubscriptionText
                                //         title={title}
                                //         theme={theme}
                                //         className={styles.text}
                                //     />
                                // </SubscriptionWizardForm>
                            )}
                        </div>
                    </div>
                </div>
            </SubscriptionBox>
        </div>
    );
}

SubscriptionBanner.propTypes = propTypes;
SubscriptionBanner.defaultProps = defaultProps;

export default SubscriptionBanner;
