/* eslint-disable react/no-danger, react/jsx-no-useless-fragment */
import { useResizeObserver } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Loadable from 'react-loadable';

import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName, pascalCase } from '../../lib/utils';

import { withArticleTheme } from '../../contexts/ArticleThemeContext';
import { useSmallLayout } from '../../contexts/LayoutContext';
import ArticleCategory from './ArticleCategory';
import ArticleMetadata from './ArticleMetadata';
import ColoredBackground from './ColoredBackground';
import Link from './Link';
// import LocationDetails from './LocationDetails';
// import Micromag from './Micromag';
import TextBackground from './TextBackground';
import Video from './Video';
import * as ImageComponents from './images';

import styles from '../../../styles/partials/article-header.module.scss';

const Micromag = Loadable({
    loader: () => import('./Micromag').then(({ default: Component }) => Component),
    loading: () => null,
});

const propTypes = {
    article: AppPropTypes.article.isRequired,
    textColor: AppPropTypes.color,
    titleColor: AppPropTypes.color,
    hideTitle: PropTypes.bool,
    hideSubtitle: PropTypes.bool,
    hideCategory: PropTypes.bool,
    linkUnderlineColor: PropTypes.string,
    summaryPosition: PropTypes.oneOf(['top', 'bottom']),
    imageTheme: PropTypes.string,
    backgroundIsBorder: PropTypes.bool,
    backgroundIsFull: PropTypes.bool,
    imageIsFull: PropTypes.bool,
    backgroundColor: AppPropTypes.color,
    withoutBackground: PropTypes.bool,
    withoutColoredBackground: PropTypes.bool,
    embedOnly: PropTypes.bool,
    isFirst: PropTypes.bool,
    isReader: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
};

const defaultProps = {
    textColor: null,
    titleColor: null,
    hideTitle: false,
    hideSubtitle: false,
    hideCategory: false,
    linkUnderlineColor: null,
    summaryPosition: null,
    imageTheme: null,
    backgroundIsBorder: false,
    backgroundIsFull: false,
    imageIsFull: false,
    backgroundColor: null,
    withoutBackground: false,
    withoutColoredBackground: false,
    embedOnly: false,
    isFirst: false,
    isReader: false,
    className: null,
    innerClassName: null,
    titleClassName: null,
};

function ArticleHeader({
    article,
    textColor,
    titleColor,
    hideTitle,
    hideSubtitle,
    hideCategory,
    linkUnderlineColor,
    summaryPosition,
    imageTheme,
    backgroundIsBorder,
    backgroundIsFull,
    imageIsFull,
    backgroundColor,
    withoutBackground,
    withoutColoredBackground,
    embedOnly,
    isFirst,
    isReader,
    className,
    innerClassName,
    titleClassName,
}) {
    const {
        type = 'article',
        title,
        subtitle = null,
        url,
        name = null,
        video = null,
        micromag = null,
        image = null,
        metadata: { section = null, site = null, theme = null } = {},
    } = article;
    const { name: articleTheme = null } = theme || {};
    const { university = null } = section || {};
    const isEmbeddedHeader = type === 'video' || type === 'micromag';
    const isDehors = site !== null && site === 'dehors';

    const {
        ref: backgroundRef,
        entry: { contentRect = null },
    } = useResizeObserver();

    const backgroundHeight = useMemo(
        () => (contentRect !== null ? contentRect.height : 0),
        [contentRect],
    );

    const isSmallScreen = useSmallLayout();

    const ImageComponent = getComponentFromName(
        ImageComponents,
        imageTheme,
        isSmallScreen ? 'Background' : 'Default',
    );

    const articleImage = (
        <div className={styles.imageContainer}>
            <ImageComponent
                className={styles.image}
                isArticle
                image={image}
                imageSize="cover"
                link={url}
                backgroundColor={backgroundColor}
            />
        </div>
    );

    const articleSummary = (
        <div
            className={classNames([
                styles.summary,
                {
                    [styles.isReader]: isReader,
                    // [className]: className !== null,
                },
            ])}
        >
            <div
                className={classNames([
                    styles.inner,
                    {
                        [innerClassName]: innerClassName !== null,
                    },
                ])}
            >
                {!isEmbeddedHeader ? (
                    <ArticleCategory article={article} className={styles.category} />
                ) : null}

                {title !== null ? (
                    <h1
                        className={classNames([
                            styles.title,
                            {
                                [titleClassName]: titleClassName !== null,
                            },
                        ])}
                        style={{
                            color: titleColor,
                        }}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                ) : null}

                {subtitle !== null ? <p className={styles.subtitle}>{subtitle}</p> : null}
                {name !== null ? (
                    <h1
                        className={classNames([
                            styles.title,
                            {
                                [titleClassName]: titleClassName !== null,
                            },
                        ])}
                    >
                        <Link href={url} className={styles.link}>
                            {name}
                        </Link>
                    </h1>
                ) : null}
                {isReader ? (
                    <ArticleMetadata
                        className={styles.metadata}
                        article={article}
                        withoutAuthorLink
                        isCentered
                        withCredits
                        isReader={isReader}
                    />
                ) : null}
                {/* {(locations || []).map((location) => (
                    <LocationDetails
                        key={location.id}
                        className={styles.locationDetails}
                        location={location}
                    />
                ))} */}
            </div>
        </div>
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hideTitle]: hideTitle,
                    [styles.hideSubtitle]: hideSubtitle,
                    [styles.hideCategory]: hideCategory,
                    [styles.backgroundIsFull]: backgroundIsFull,
                    [styles.imageIsFull]: imageIsFull,
                    [styles.backgroundIsBorder]: backgroundIsBorder,
                    [styles.isDark]: isEmbeddedHeader,
                    [styles.hasColoredBackground]: isDehors && !withoutColoredBackground,
                    [styles.embedOnly]: isEmbeddedHeader && embedOnly,
                    [styles[`theme${pascalCase(articleTheme || '')}`]]:
                        typeof styles[`theme${pascalCase(articleTheme || '')}`] !== 'undefined',
                    [className]: className !== null,
                },
            ])}
        >
            {isDehors && !withoutColoredBackground ? (
                <ColoredBackground
                    className={classNames([
                        styles.coloredBackground,
                        { [styles.embedded]: isEmbeddedHeader === true },
                    ])}
                />
            ) : null}

            {!withoutBackground && !isEmbeddedHeader ? (
                <div
                    className={styles.background}
                    style={{
                        backgroundColor,
                    }}
                    ref={backgroundRef}
                >
                    {university !== null ? (
                        <TextBackground
                            text={university.shortName}
                            color={university.primaryColor}
                            height={backgroundHeight - 50}
                            className={styles.university}
                        />
                    ) : null}
                </div>
            ) : null}

            <div
                className={styles.top}
                style={{
                    backgroundColor: isEmbeddedHeader ? backgroundColor : null,
                }}
            >
                {type === 'video' ? (
                    <>
                        {video !== null ? (
                            <Video
                                video={video}
                                autoPlay
                                className={styles.embed}
                                fullscreen={embedOnly}
                            />
                        ) : (
                            articleImage
                        )}
                    </>
                ) : null}
                {type === 'micromag' ? (
                    <>
                        {micromag !== null ? (
                            <Micromag
                                micromag={micromag}
                                autoFullscreen
                                fullscreen={isFirst}
                                className={styles.embed}
                            />
                        ) : (
                            articleImage
                        )}
                    </>
                ) : null}
                {!isEmbeddedHeader ? (
                    <>
                        {!isSmallScreen || summaryPosition === 'top'
                            ? articleSummary
                            : articleImage}
                    </>
                ) : null}
            </div>
            {!embedOnly || !isEmbeddedHeader ? (
                <div
                    className={classNames([
                        styles.bottom,
                        {
                            [styles.withSidePadding]:
                                summaryPosition === 'top' && !isEmbeddedHeader,
                        },
                    ])}
                >
                    {isEmbeddedHeader || (isSmallScreen && summaryPosition !== 'top')
                        ? articleSummary
                        : articleImage}
                </div>
            ) : null}
        </div>
    );
}

ArticleHeader.propTypes = propTypes;
ArticleHeader.defaultProps = defaultProps;

const WithArticleThemeContainer = withArticleTheme(
    ArticleHeader,
    ({
        headerBackgroundColor,
        headerTextColor,
        headerTitleColor,
        headerBackgroundIsBorder = true,
        headerBackgroundIsFull = false,
        headerImageIsFull = false,
    }) => ({
        backgroundIsFull: headerBackgroundIsFull,
        imageIsFull: headerImageIsFull,
        backgroundIsBorder: headerBackgroundIsBorder,
        backgroundColor: headerBackgroundColor,
        textColor: headerTextColor,
        titleColor: headerTitleColor,
    }),
);

export default WithArticleThemeContainer;
