import Loadable from 'react-loadable';

export const Home = Loadable({
    loader: () =>
        import('./index.main').then(
            ({ Home: Component }) => Component,
        ),
    loading: () => null,
});
Home.displayName = 'Home';

export const Article = Loadable({
    loader: () =>
        import('./index.main').then(
            ({ Article: Component }) => Component,
        ),
    loading: () => null,
});
Article.displayName = 'Article';

// ArticlePreview
export const ArticlePreview = Loadable({
    loader: () => import('./ArticlePreview').then(({ default: Component }) => Component),
    loading: () => null,
});
ArticlePreview.displayName = 'ArticlePreview';

// ArticlePreview
export const Micromag = Loadable({
    loader: () => import('./Micromag').then(({ default: Component }) => Component),
    loading: () => null,
});
Micromag.displayName = 'Micromag';

export const ErrorPage = Loadable({
    loader: () =>
        import('./index.main').then(
            ({ ErrorPage: Component }) => Component,
        ),
    loading: () => null,
});
ErrorPage.displayName = 'ErrorPage';

export const Index = Loadable({
    loader: () =>
        import('./index.main').then(
            ({ Index: Component }) => Component,
        ),
    loading: () => null,
});
Index.displayName = 'Index';

export const IndexItem = Loadable({
    loader: () =>
        import('./index.main').then(
            ({ IndexItem: Component }) => Component,
        ),
    loading: () => null,
});
IndexItem.displayName = 'IndexItem';

// Section
export const Section = Loadable({
    loader: () =>
        import('./index.main').then(
            ({ Section: Component }) => Component,
        ),
    loading: () => null,
});
Section.displayName = 'Section';

// Search
export const Search = Loadable({
    loader: () => import('./Search').then(({ default: Component }) => Component),
    loading: () => null,
});
Search.displayName = 'Search';

// Collection
export const Collection = Loadable({
    loader: () => import('./Collection').then(({ default: Component }) => Component),
    loading: () => null,
});
Collection.displayName = 'Collection';

// Magazine
export const Magazine = Loadable({
    loader: () => import('./Magazine').then(({ default: Component }) => Component),
    loading: () => null,
});
Magazine.displayName = 'Magazine';

// Page
export const Page = Loadable({
    loader: () => import('./Page').then(({ default: Component }) => Component),
    loading: () => null,
});
Page.displayName = 'Page';

// Quiz
export const Quiz = Loadable({
    loader: () => import('./PageQuiz').then(({ default: Component }) => Component),
    loading: () => null,
});
Quiz.displayName = 'Quiz';
