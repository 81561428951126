import { useResizeObserver, useWindowScroll } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { useLayoutOptions, useLayoutScrollDisabled } from '../../contexts/LayoutContext';
import { useSite, withTheme } from '../../contexts/SiteContext';
import Header from '../headers/Header';
import LoadingBar from '../partials/LoadingBar';

import styles from '../../../styles/layouts/reader.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    pageIsLoaded: PropTypes.bool,
    page: AppPropTypes.page,
    loadingColor: PropTypes.string,
    textColor: PropTypes.string,
};

const defaultProps = {
    pageIsLoaded: false,
    page: null,
    loadingColor: null,
    textColor: null,
};

function ReaderLayout({ children, pageIsLoaded, page, loadingColor, textColor }) {
    const site = useSite();

    const scrollDisabled = useLayoutScrollDisabled();
    const { y: scrollY = 0 } = useWindowScroll();

    // old layout if theme !== card in a query string;
    // const { search } = useLocation();
    // const { theme = null } = useMemo(() => parse(search), [search]);
    // const isCardTheme = theme === 'card';
    const theme = 'card';
    const isCardTheme = true;

    // Header height (not needed for card theme)
    const {
        ref: refHeader,
        entry: { contentRect: headerContentRect },
    } = useResizeObserver();
    const { height: realHeaderHeight = site.id === 'quatre95' ? 70 : 80 } = headerContentRect || {};
    const headerHeightRef = useRef(realHeaderHeight);
    if (realHeaderHeight > 0) {
        headerHeightRef.current = realHeaderHeight;
    }
    const { current: headerHeight } = headerHeightRef;
    useEffect(() => {
        document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`);
    }, [headerHeight]);

    const {
        headerTransparent,
        headerTextColor = null, // always null?
    } = useLayoutOptions();

    const headerRatio = site.id === 'dehors' ? 2 : 4;

    const headerSticky = scrollY > headerHeight / headerRatio;

    const { content_min_height_viewport: contentMinHeightViewport = false, type: articleType } =
        page || {};
    const isEmbed = articleType === 'video' || articleType === 'micromag';

    return (
        <div
            className={classNames([
                styles.container,
                styles[theme],
                {
                    [styles.isFullscreen]: isEmbed,
                    [styles.scrollDisabled]: scrollDisabled,
                },
            ])}
            style={{
                color: textColor,
            }}
        >
            <div className={styles.loading}>
                <LoadingBar loading={!pageIsLoaded} loaded={pageIsLoaded} color={loadingColor} />
            </div>
            <Header
                ref={refHeader}
                sticky={isCardTheme ? false : headerSticky}
                transparent={isCardTheme ? true : headerTransparent && !headerSticky}
                theme={theme}
                textColor={isCardTheme ? '#00000' : headerTextColor}
                page={page}
                className={styles.header}
            />
            <div className={styles.inner}>
                <div
                    className={classNames([
                        styles.content,
                        {
                            [styles.minHeightViewport]: contentMinHeightViewport,
                        },
                    ])}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

ReaderLayout.propTypes = propTypes;
ReaderLayout.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    ReaderLayout,
    ({ textColor, header: { loadingColor } = {} }) => ({
        loadingColor,
        textColor,
    }),
);

export default WithThemeContainer;
