/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import MapPinCircle from '../icons/MapPinCircle';
import Button from './Button';

import styles from '../../../styles/buttons/view-map.module.scss';

const propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

const defaultProps = {
    className: null,
};

function ViewMapButton({ className, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <div className={styles.label}>
                <FormattedMessage
                    defaultMessage="Localisez les lieux sur la carte Dehors!"
                    description="button label"
                />
            </div>
            <MapPinCircle className={styles.icon} circleClassName={styles.circle} />
        </Button>
    );
}

ViewMapButton.propTypes = propTypes;
ViewMapButton.defaultProps = defaultProps;

export default ViewMapButton;
