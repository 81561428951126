/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { withSite } from '../../contexts/SiteContext';
import GuideIcon from '../icons/logos/UniversitiesGuideCircle';
import LinkStyle from '../partials/LinkStyle';

import styles from '../../../styles/sections/contact.module.scss';

const propTypes = {
    site: AppPropTypes.site.isRequired,
    color: AppPropTypes.color,
    backgroundColor: AppPropTypes.color,
    linkUnderlineColor: AppPropTypes.color,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#39f',
    backgroundColor: '#e1f0ff',
    linkUnderlineColor: '#3399ff33',
    className: null,
};

export const Contact = ({ site, color, backgroundColor, linkUnderlineColor, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        style={{ color, backgroundColor }}
    >
        <LinkStyle selector={`.${styles.description}`} underlineColor={linkUnderlineColor} />
        <div className={styles.inner}>
            <GuideIcon className={styles.icon} color={color} />
            <div className={styles.textBox}>
                <h4 className={styles.title}>
                    <FormattedMessage
                        defaultMessage="Vous connaissez les secrets{br}les mieux gardés de votre université?"
                        description="Block title"
                    />
                </h4>
                <div className={styles.description}>
                    <FormattedMessage
                        defaultMessage="Écrivez-nous au {email} pour devenir un de nos collaborateurs et faites rayonner vos meilleures histoires universitaires, votre vie étudiante ou vos mèmes savoureux sur le café de votre faculté."
                        description="Block description"
                        values={{
                            email: <a href={`mailto:${site.email}`}>{site.email}</a>,
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
);

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default withSite(React.memo(Contact));
