import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';

import useOnClickLink from '../../hooks/useOnClickLink';
import { useTrackLink } from '../../hooks/useTracking';

// import * as AppPropTypes from '../../lib/PropTypes';
import Button from '../buttons/Button';
import Arrow from '../icons/Arrow';
import Video from '../partials/Video';

import styles from '../../../styles/sections/lair-daller-trailer.module.scss';

import logoTeleQuebec from '../../../img/logo-tele-quebec.svg';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function LairDallerTrailerBanner({ className }) {
    const video = useMemo(
        () => ({
            // id: '752170742:fad4a0852c',
            provider: 'vimeo',
            embed: 'https://player.vimeo.com/video/752170742?h=fad4a0852c',
            width: 1280,
            height: 720,
            title: 'Bande-Annonce - L’air d’aller',
        }),
        [],
    );

    const trackLink = useTrackLink();
    const onClickLink = useCallback(({ url: linkUrl }) => trackLink(linkUrl), [trackLink]);
    const onClickButton = useOnClickLink(onClickLink);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <Video video={video} autoPlay withLazyLoading className={styles.video} />
                <Button
                    external
                    href="https://video.telequebec.tv/details/43162"
                    className={styles.button}
                    onClick={onClickButton}
                >
                    <img src={logoTeleQuebec} alt="" className={styles.buttonLogo} />
                    <span>
                        voir sur <span className={styles.sponsor}>Télé-Québec</span>
                    </span>
                    <Arrow backgroundColor="#fff" className={styles.buttonArrow} />
                </Button>
            </div>
        </div>
    );
}

LairDallerTrailerBanner.propTypes = propTypes;
LairDallerTrailerBanner.defaultProps = defaultProps;

export default LairDallerTrailerBanner;
