import { useResizeObserver, useWindowScroll } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useFonts from '../../hooks/useFonts';

// import * as AppPropTypes from '../../lib/PropTypes';
import { useSite, withTheme } from '../../contexts/SiteContext';
import HeaderOverlay from '../headers/HeaderOverlay';
import LoadingBar from '../partials/LoadingBar';
import defaultFonts from './fonts';

import styles from '../../../styles/layouts/fullscreen.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    pageIsLoaded: PropTypes.bool,
    loadingColor: PropTypes.string,
    textColor: PropTypes.string,
    fonts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
};

const defaultProps = {
    pageIsLoaded: false,
    loadingColor: null,
    textColor: null,
    fonts: defaultFonts,
};

function FullscreenLayout({ fonts, children, pageIsLoaded, loadingColor, textColor }) {
    const site = useSite();
    const finalFonts = useMemo(
        () =>
            site.id === 'quatre95'
                ? fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Apercu')
                : fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Maax'),
        [fonts, site.id],
    );
    const { loaded: fontsLoaded } = useFonts(finalFonts);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.fontsLoaded]: fontsLoaded,
                },
            ])}
            style={{
                color: textColor,
            }}
        >
            <div className={styles.loading}>
                <LoadingBar loading={!pageIsLoaded} loaded={pageIsLoaded} color={loadingColor} />
            </div>
            <div className={styles.inner}>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
}

FullscreenLayout.propTypes = propTypes;
FullscreenLayout.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    FullscreenLayout,
    ({ textColor, header: { loadingColor } = {} }) => ({
        loadingColor,
        textColor,
    }),
);

export default WithThemeContainer;
