import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const DehorsIcon = ({ color, className }) => (
    <svg
        width="75"
        height="20"
        viewBox="0 0 75 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M12.9282 1.08105L9.23905 1.08105V3.66777H10.1596V8.91016C9.68904 8.29777 9.08432 7.80139 8.39194 7.45916C7.69956 7.11693 6.93796 6.93795 6.16562 6.93598C2.73891 6.93598 0.015625 9.81777 0.015625 13.1993C0.015625 16.5575 2.80697 19.4397 6.18846 19.4397C7.8004 19.4397 9.25159 18.8043 10.1596 17.4427V19.2579H13.8259L13.8259 16.6726H12.9304L12.9282 1.08105ZM6.55114 16.8996C4.53174 16.8996 2.89786 15.2429 2.89786 13.2458C2.89786 11.1808 4.53174 9.52404 6.59682 9.52404C8.57099 9.52404 10.2277 11.1579 10.2277 13.1549C10.2277 15.1976 8.61622 16.8996 6.55114 16.8996Z"
            fill={color}
        />
        <path
            d="M20.6425 6.7793C17.0344 6.7793 14.334 9.66109 14.334 13.2468C14.334 16.8096 17.1706 19.5781 20.7106 19.5781C23.2297 19.5781 25.4761 18.0109 26.4294 15.6978H23.5489C22.8683 16.6963 21.8241 17.1499 20.6215 17.1499C18.8971 17.1499 17.3528 15.9244 16.9906 14.2453H26.7938L26.8834 13.2696C26.8834 11.5448 26.3841 10.0009 25.1819 8.73109C24.0029 7.52795 22.3234 6.7793 20.6425 6.7793ZM16.9664 12.1121C17.3067 10.5235 18.8725 9.25273 20.5064 9.25273C22.2974 9.25273 23.8422 10.365 24.3186 12.1121H16.9664Z"
            fill={color}
        />
        <path
            d="M39.1853 12.4972C39.1853 11.0446 39.0957 9.86479 38.1868 8.63927C37.3696 7.52703 36.1669 6.86927 34.7601 6.86927C33.1929 6.86927 31.9007 7.59509 31.1292 8.95673V1.08105L27.4629 1.08105V3.66777H28.3607V16.6725H27.4629V19.2593H32.0269V16.6725H31.1292V12.9964C31.1292 12.0884 31.1063 11.3169 31.7193 10.5682C32.2186 9.93285 32.9901 9.5921 33.8068 9.5921C34.692 9.5921 35.5083 9.93285 36.008 10.7043C36.4848 11.4758 36.4168 12.3611 36.4168 13.2458V16.6725H35.5213V19.2593H40.083V16.6725H39.1875L39.1853 12.4972Z"
            fill={color}
        />
        <path
            d="M61.1027 9.20705V7.14198H57.6406V9.72914H58.5361V16.673H57.6406V19.2597H62.2046V16.673H61.3091V13.6094C61.3091 12.4748 61.4435 11.4991 62.3529 10.7272C63.0339 10.1147 63.8506 9.88765 64.7358 9.84243V7.00586C63.1678 7.07481 61.8061 7.77735 61.1027 9.20705Z"
            fill={color}
        />
        <path
            d="M70.4834 11.8613C69.5986 11.6571 68.4864 11.4077 68.4864 10.2731C68.4864 9.43354 69.2807 9.00234 70.0298 9.00234C70.8917 9.00234 71.5522 9.56965 71.597 10.432H74.2746V7.14145L72.2323 7.14145V7.981C71.5513 7.11862 70.5756 6.8916 69.4862 6.8916C67.3307 6.8916 65.3789 8.23085 65.3789 10.5453C65.3789 13.2005 67.6934 13.8588 69.8041 14.4032C70.7346 14.6526 71.8692 14.8568 71.8692 16.0599C71.8692 16.968 71.052 17.5577 70.2125 17.5577C69.0779 17.5577 68.3516 16.8319 68.2611 15.7425H65.4241V19.2596H67.5349V18.2611C68.3292 19.1916 69.441 19.5775 70.6665 19.5775C72.9358 19.5775 75.0009 18.2159 75.0009 15.7653C75.0009 13.0416 72.6622 12.3605 70.4834 11.8613Z"
            fill={color}
        />
        <path
            d="M48.305 -0.0185547C43.6524 -0.0185547 39.8809 3.75294 39.8809 8.40518C39.8809 13.0574 43.6546 16.8294 48.305 16.8294C52.9555 16.8294 56.7292 13.0579 56.7292 8.40518C56.7292 3.75249 52.9573 -0.0185547 48.305 -0.0185547ZM48.305 14.0474C47.1892 14.0474 46.0984 13.7165 45.1706 13.0966C44.2428 12.4767 43.5197 11.5955 43.0927 10.5646C42.6657 9.53374 42.554 8.39937 42.7717 7.30497C42.9893 6.21057 43.5267 5.2053 44.3157 4.41628C45.1047 3.62726 46.11 3.08993 47.2044 2.87224C48.2988 2.65455 49.4332 2.76628 50.4641 3.19329C51.495 3.6203 52.3761 4.34343 52.996 5.27122C53.6159 6.199 53.9468 7.28979 53.9468 8.40563C53.9451 9.90138 53.3501 11.3354 52.2924 12.393C51.2348 13.4507 49.8008 14.0456 48.305 14.0474V14.0474Z"
            fill={color}
        />
    </svg>
);

DehorsIcon.propTypes = propTypes;
DehorsIcon.defaultProps = defaultProps;

export default DehorsIcon;
