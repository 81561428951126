/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// import * as AppPropTypes from '../../lib/PropTypes';
import Modal from './Modal';
import CloseCircleButton from '../buttons/CloseCircle';
import SubscriptionField from '../subscription/SubscriptionField';

import styles from '../../../styles/popups/newsletter-form-modal.module.scss';
// import usePopupTransitions from '../../hooks/usePopupTransitions';

const propTypes = {
    source: PropTypes.string,
    opened: PropTypes.bool,
    closeAfterSuccess: PropTypes.number,
    closePopup: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    source: null,
    opened: null,
    closeAfterSuccess: 1000,
    onSuccess: null,
    className: null,
};

function NewsletterFormModal ({
    closeAfterSuccess,
    source,
    opened: initialOpened,
    closePopup,
    onSuccess: customOnSuccess,
    className,
}) {
    const [opened, setOpened] = useState(initialOpened);
    const [success, setSuccess] = useState(false);
    const refContainer = useRef(null);

    const onRequestClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);

    const onSuccess = useCallback(() => {
        setSuccess(true);
        if (customOnSuccess !== null) {
            customOnSuccess();
        }
    }, [setSuccess, customOnSuccess]);

    useEffect(() => {
        setOpened(initialOpened !== null ? initialOpened : true);
    }, [initialOpened, setOpened]);

    useEffect(() => {
        const container = refContainer.current;
        disableBodyScroll(container);
        return () => {
            enableBodyScroll(container);
        };
    }, []);

    useEffect(() => {
        let timeout = null;
        if (success) {
            timeout = setTimeout(() => {
                setOpened(false);
            }, closeAfterSuccess);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [success, setOpened, closeAfterSuccess]);

    return (
        <Modal
            className={classNames([
                styles.container,
                {
                    [styles.success]: success === true,
                    [className]: className !== null,
                },
            ])}
            onClickSafe={onRequestClose}
            onClosed={closePopup}
            opened={opened}
        >
            <div className={styles.inner} ref={refContainer}>
                {success ? (
                    <div className={styles.thankYou}>
                        <FormattedMessage defaultMessage="Merci" description="Success message" />
                    </div>
                ) : (
                    <>
                        <label className={styles.label} htmlFor="newsletterEmail">
                            <FormattedMessage
                                defaultMessage="Inscription à l’infolettre"
                                description="Modal title"
                            />
                        </label>
                        <SubscriptionField
                            className={styles.form}
                            inputClassName={styles.input}
                            buttonClassName={styles.button}
                            buttonIcon={<div className={styles.icon}>→</div>}
                            onSubscribed={onSuccess}
                            source={source}
                            autoFocus={opened}
                        />
                    </>
                )}
                <CloseCircleButton className={styles.closeButton} onClick={onRequestClose} />
            </div>
        </Modal>
    );
};

NewsletterFormModal.propTypes = propTypes;
NewsletterFormModal.defaultProps = defaultProps;

export default NewsletterFormModal;
