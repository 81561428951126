/* eslint-disable react/no-danger, react/jsx-props-no-spreading */
import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from '../../contexts/SiteContext';
import useElementSize from '../../hooks/useElementSize';
import { useTrackEvent } from '../../hooks/useTracking';

import Arrow from '../icons/Arrow';
import Close from '../icons/Close';

import FromagesIciVert from '../../../img/ads/header-fromages-vert.png';
import FromagesIciRouge from '../../../img/ads/header-fromages-rouge.png';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/ad-splash.module.scss';

const images = { fromages: [FromagesIciVert, FromagesIciRouge] };

const propTypes = {
    siteName: PropTypes.string,
    data: PropTypes.shape({
        enabled: PropTypes.bool.isRequired,
        name: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        src: PropTypes.string,
        tags: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
    }),
    linkUnderlineColor: AppPropTypes.color,
    toggleVisible: PropTypes.func.isRequired,
    onHeightChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    siteName: 'urbania.ca',
    data: {},
    linkUnderlineColor: null,
    className: null,
};

const AdSplash = ({
    siteName,
    data,
    linkUnderlineColor,
    toggleVisible,
    onHeightChange,
    className,
}) => {
    const {
        name = 'Advertising',
        url = '',
        type = 'image',
        src = 'telus',
        tags = '',
        width: adWidth = 320,
        height: adHeight = 480,
    } = data;

    const trackEvent = useTrackEvent();
    const containerRef = useRef(null);
    const elementSize = useElementSize(containerRef.current);
    const { height = 0 } = elementSize || {};

    const imgSrc = useMemo(
        () =>
            type === 'image' && src && images[src]
                ? [Math.random() > 0.5 ? images[src][0] : images[src][1]] || null
                : null,
        [type, src, images],
    );

    useEffect(() => {
        onHeightChange(height);
    }, [height, onHeightChange]);

    useEffect(() => {
        if (name && url) {
            trackEvent('Ads', 'view', name, url);
        }
    }, [trackEvent, name, url]);

    const onClickClose = useCallback(() => {
        toggleVisible(false);
    }, [toggleVisible]);

    const onClickAd = useCallback(() => {
        if (name && url) {
            trackEvent('Ads', 'click', name, url);
        }
    }, [trackEvent, name, url]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            <button className={styles.closeButton} type="button" onClick={onClickClose}>
                <Close className={styles.close} withCircle />
            </button>
            <a
                className={styles.asset}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickAd}
            >
                {type === 'image' ? (
                    <img
                        src={imgSrc}
                        alt={name}
                        style={{
                            width: adWidth,
                            height: adHeight,
                        }}
                    />
                ) : null}
                {type === 'iframe' ? (
                    <iframe
                        title={name}
                        src={src}
                        width={adWidth}
                        height={adHeight}
                        style={{
                            width: adWidth,
                            height: adHeight,
                        }}
                    />
                ) : null}
            </a>
            <button className={styles.next} type="button" onClick={onClickClose}>
                <span className={styles.call}>
                    <FormattedMessage
                        defaultMessage="Poursuivre sur"
                        description="Ad call to action"
                    />
                </span>
                <span
                    className={styles.siteName}
                    style={{
                        backgroundImage:
                            linkUnderlineColor !== null
                                ? `linear-gradient(0deg, ${linkUnderlineColor} 0, ${linkUnderlineColor})`
                                : null,
                    }}
                >
                    {siteName}
                </span>
                <Arrow className={styles.arrow} color="#000" backgroundColor="transparent" />
            </button>
            <div className={styles.pixels} dangerouslySetInnerHTML={{ __html: tags }} />
        </div>
    );
};

AdSplash.propTypes = propTypes;
AdSplash.defaultProps = defaultProps;

export default withTheme(
    AdSplash,
    ({ darkLinkUnderlineColor, linkUnderlineColor }, { isDark = false }) => ({
        linkUnderlineColor: isDark ? darkLinkUnderlineColor : linkUnderlineColor,
    }),
);
