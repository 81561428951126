/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { getContrastingColor } from '../../lib/utils';
import { withTheme } from '../../contexts/SiteContext';
import useFacebook from '../../hooks/useFacebook';
import CommentsIcon from '../icons/Comments';
import CaretIcon from '../icons/Caret';

import styles from '../../../styles/sections/comments.module.scss';

const propTypes = {
    url: PropTypes.string.isRequired,
    count: PropTypes.number,
    backgroundColor: AppPropTypes.color.isRequired,
    buttonTextStyle: AppPropTypes.textStyle,
    className: PropTypes.string,
};

const defaultProps = {
    count: null,
    buttonTextStyle: null,
    className: null,
};

function Comments({ url, count, backgroundColor, buttonTextStyle, className }) {
    const [opened, setOpened] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);
    const textColor = useMemo(() => getContrastingColor(backgroundColor), [backgroundColor]);
    const onClick = useCallback(() => {
        const newOpened = !opened;
        setOpened(newOpened);
        if (newOpened) {
            setHasOpened(true);
        }
    }, [opened, hasOpened]);

    // Load facebook widgets
    const refComments = useRef(null);
    const { FB } = useFacebook();
    useEffect(() => {
        if (hasOpened && FB !== null && refComments.current !== null) {
            FB.XFBML.parse(refComments.current);
        }
    }, [refComments.current, FB, hasOpened]);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ color: textColor, backgroundColor }}
        >
            <div className={styles.inner}>
                <div className={styles.box}>
                    <h2 className={styles.title}>
                        <FormattedMessage
                            defaultMessage="Vous n’allez pas rester là sans rien dire ?"
                            description="Block title"
                        />
                    </h2>
                    <h2 className={styles.title}>
                        <FormattedMessage
                            defaultMessage="Faites vous entendre..."
                            description="Block title"
                        />
                    </h2>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={onClick}
                        style={buttonTextStyle}
                    >
                        <CommentsIcon count={count !== null ? count : null} />
                        <span className={styles.label}>
                            <FormattedMessage
                                defaultMessage="Afficher les commentaires"
                                description="Button label"
                            />
                        </span>
                        <CaretIcon
                            className={classNames([
                                styles.caret,
                                {
                                    [styles.opened]: opened,
                                },
                            ])}
                        />
                    </button>
                </div>
                <div
                    className={classNames([
                        styles.comments,
                        {
                            [styles.opened]: opened,
                        },
                    ])}
                    ref={refComments}
                >
                    {hasOpened ? (
                        <div
                            className="fb-comments"
                            data-href={url}
                            data-width={1040}
                            data-numposts="5"
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

export default withTheme(Comments, ({ fonts: { otherTextStyle } = {} }) => ({
    buttonTextStyle: otherTextStyle,
}));
