/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useSubscribed from '../../hooks/useSubscribed';
import useSubscriptionVariant from '../../hooks/useSubscriptionVariant';

import SubscriptionBaseSection from '../subscription/SubscriptionSection';

const propTypes = {
    subscription: PropTypes.string,
    variant: PropTypes.string,
};

const defaultProps = {
    subscription: null,
    variant: null,
};

function SubscriptionSection({ subscription, variant: providedVariant, ...props }) {
    const subscribed = useSubscribed(subscription);

    const { variant } = useSubscriptionVariant({
        subscription,
        variant: providedVariant,
        placement: 'section',
        source: 'section',
    });

    const finalSubscribed = useMemo(() => subscribed, []);
    return !finalSubscribed ? <SubscriptionBaseSection {...variant} {...props} /> : null;
}

SubscriptionSection.propTypes = propTypes;
SubscriptionSection.defaultProps = defaultProps;

export default SubscriptionSection;
