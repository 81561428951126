import { useResizeObserver, useWindowScroll } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useFonts from '../../hooks/useFonts';

// import * as AppPropTypes from '../../lib/PropTypes';
import { useSite, withTheme } from '../../contexts/SiteContext';
import HeaderEmail from '../headers/HeaderEmail';
import LoadingBar from '../partials/LoadingBar';
import Popup from '../popups/Popup';
import defaultFonts from './fonts';

import styles from '../../../styles/layouts/email.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    pageIsLoaded: PropTypes.bool,
    backgroundColor: PropTypes.string,
    loadingColor: PropTypes.string,
    textColor: PropTypes.string,
    fonts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
};

const defaultProps = {
    pageIsLoaded: false,
    backgroundColor: null,
    loadingColor: null,
    textColor: null,
    fonts: defaultFonts,
};

function EmailLayout({ fonts, children, pageIsLoaded, backgroundColor, loadingColor, textColor }) {
    const site = useSite();
    const finalFonts = useMemo(
        () =>
            site.id === 'quatre95'
                ? fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Apercu')
                : fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Maax'),
        [fonts, site.id],
    );
    const { loaded: fontsLoaded } = useFonts(finalFonts);
    const { y: scrollY } = useWindowScroll();
    const {
        ref: refHeaderResize,
        entry: { contentRect: headerContentRect },
    } = useResizeObserver();
    const { height: headerHeight = 80 } = headerContentRect || {};

    const headerSticky = scrollY > headerHeight / 4;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.fontsLoaded]: fontsLoaded,
                },
            ])}
            style={{
                backgroundColor,
                color: textColor,
            }}
        >
            <div className={styles.loading}>
                <LoadingBar loading={!pageIsLoaded} loaded={pageIsLoaded} color={loadingColor} />
            </div>
            <HeaderEmail
                ref={refHeaderResize}
                sticky={headerSticky}
                className={classNames([
                    styles.header,
                    {
                        [styles.sticky]: headerSticky,
                    },
                ])}
                barClassName={styles.bar}
            />
            <div className={styles.inner}>
                <div className={styles.content}>{children}</div>
            </div>
            <Popup />
        </div>
    );
}

EmailLayout.propTypes = propTypes;
EmailLayout.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    EmailLayout,
    ({ backgroundColor, textColor, header: { loadingColor } = {} }) => ({
        backgroundColor,
        loadingColor,
        textColor,
    }),
);

export default WithThemeContainer;
