/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';

import useBlocksWithAddons from '../../hooks/useBlocksWithAddons';
import useOnClickLink from '../../hooks/useOnClickLink';
import useSubscribed from '../../hooks/useSubscribed';
import useSubscriptionVariant from '../../hooks/useSubscriptionVariant';
import { useTrackLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';
import { hexToRGB, getContrastingColor, pascalCase } from '../../lib/utils';

import { ArticleThemeProvider } from '../../contexts/ArticleThemeContext';
import { useNewAudiences } from '../../contexts/AudiencesContext';
import { useSite, withTheme } from '../../contexts/SiteContext';
import Blocks from '../blocks/Blocks';
import ContactBlock from '../blocks/Contact';
import ContestBlock from '../blocks/Contest';
import LocationsBlock from '../blocks/Locations';
import SubscriptionBlock from '../blocks/Subscription';
import Ad from './Ad';
import ArticleHeader from './ArticleHeader';
import ArticleMetadata from './ArticleMetadata';
import LinkStyle from './LinkStyle';
import PresentedBy from './PresentedBy';

import styles from '../../../styles/partials/article.module.scss';

const propTypes = {
    article: AppPropTypes.article.isRequired,
    infiniteScrollIndex: PropTypes.number,
    themeName: PropTypes.string,
    primaryColor: AppPropTypes.color,
    textColor: AppPropTypes.color,
    backgroundColor: AppPropTypes.color,
    headerBackgroundColor: AppPropTypes.color,
    headerTextColor: AppPropTypes.color,
    headerTitleColor: AppPropTypes.color,
    headerBackgroundIsFull: PropTypes.bool,
    headerImageIsFull: PropTypes.bool,
    backgroundImage: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    headerBackgroundIsBorder: PropTypes.bool,
    withoutHeaderBackground: PropTypes.bool,
    withoutHeaderColoredBackground: PropTypes.bool,
    headerImageTheme: PropTypes.string,
    withoutHeader: PropTypes.bool,
    hideTitle: PropTypes.bool,
    hideSubtitle: PropTypes.bool,
    hideCategory: PropTypes.bool,
    summaryPosition: PropTypes.oneOf(['top', 'bottom']),
    metadataTextColor: AppPropTypes.color,
    metadataBackgroundColor: AppPropTypes.color,
    withoutAuthorLink: PropTypes.bool,
    isReader: PropTypes.bool,
    adBackgroundColor: AppPropTypes.color,
    linkUnderlineColor: AppPropTypes.color,
    headingColor: AppPropTypes.color,
    headingTextStyle: AppPropTypes.textStyle,
    quoteColor: AppPropTypes.color,
    quoteBorderColor: AppPropTypes.color,
    quoteTextStyle: AppPropTypes.textStyle,
    contactColor: AppPropTypes.color,
    newsletterColor: AppPropTypes.color,
    newsletterIconComponent: PropTypes.string,
    relatedColor: AppPropTypes.color,
    withoutTopAds: PropTypes.bool,
    withNewsletter: PropTypes.bool,
    withContact: PropTypes.bool,
    withoutSidebars: PropTypes.bool,
    withoutAds: PropTypes.bool,
    adsDisabled: PropTypes.bool,
    withoutAddons: PropTypes.bool,
    embedOnly: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    infiniteScrollIndex: 0,
    themeName: null,
    primaryColor: null,
    textColor: null,
    backgroundColor: null,
    headerBackgroundColor: null,
    headerTextColor: null,
    headerTitleColor: null,
    headerBackgroundIsFull: false,
    headerImageIsFull: false,
    backgroundImage: null,
    backgroundRepeat: null,
    headerBackgroundIsBorder: false,
    withoutHeaderBackground: false,
    withoutHeaderColoredBackground: false,
    headerImageTheme: null,
    withoutHeader: false,
    hideTitle: false,
    hideSubtitle: false,
    hideCategory: false,
    summaryPosition: PropTypes.oneOf(['top', 'bottom']),
    metadataTextColor: null,
    metadataBackgroundColor: null,
    withoutAuthorLink: false,
    isReader: false,
    adBackgroundColor: null,
    linkUnderlineColor: null,
    headingColor: null,
    headingTextStyle: null,
    quoteColor: null,
    quoteBorderColor: null,
    quoteTextStyle: null,
    contactColor: null,
    newsletterColor: null,
    newsletterIconComponent: null,
    relatedColor: null,
    withoutTopAds: false,
    withNewsletter: false,
    withContact: false,
    withoutSidebars: false,
    withoutAds: false,
    embedOnly: false,
    adsDisabled: false,
    withoutAddons: false,
    className: null,
};

function Article({
    article,
    themeName,
    infiniteScrollIndex,
    primaryColor,
    textColor,
    backgroundColor,
    headerBackgroundColor,
    headerTextColor,
    headerTitleColor,
    headerBackgroundIsFull,
    headerImageIsFull,
    backgroundImage,
    backgroundRepeat,
    headerBackgroundIsBorder,
    withoutHeaderBackground,
    withoutHeaderColoredBackground,
    headerImageTheme,
    withoutHeader,
    hideTitle,
    hideSubtitle,
    hideCategory,
    summaryPosition,
    metadataTextColor,
    metadataBackgroundColor,
    withoutAuthorLink,
    isReader,
    adBackgroundColor,
    linkUnderlineColor,
    headingColor,
    headingTextStyle,
    contactColor,
    quoteColor,
    quoteBorderColor,
    quoteTextStyle,
    newsletterColor,
    newsletterIconComponent,
    relatedColor,
    withoutTopAds,
    withNewsletter,
    withContact,
    withoutAds,
    withoutSidebars,
    embedOnly,
    adsDisabled,
    withoutAddons,
    className,
}) {
    const {
        theme: { displayMapLinks = false },
    } = useSite();

    const { type, blocks = [], contest = null, metadata } = article;
    const { blocks: _, ...articleWithoutBlocks } = article;
    const { sponsors = null, locations = null, tracking = null, authors } = metadata || {};
    const [author = null] = authors || [];
    const { id: authorId = null, slug: authorSlug = null } = author || {};
    const { audiences = null } = tracking || {};
    useNewAudiences(audiences);

    const withSponsors = sponsors !== null && sponsors.length > 0;
    const isDark = type === 'video';
    const isArticle = type === 'article';
    const isEmbed = type === 'video' || type === 'micromag';

    const articleTheme = useMemo(
        () => ({
            name: themeName,
            primaryColor,
            textColor,
            headerBackgroundColor,
            headerTextColor,
            headerTitleColor,
            headerBackgroundIsFull,
            headerImageIsFull,
            backgroundImage,
            backgroundRepeat,
            headerBackgroundIsBorder,
            headerImageTheme,
            withoutHeader,
            hideTitle,
            hideSubtitle,
            summaryPosition,
            metadataTextColor,
            metadataBackgroundColor,
            withoutAuthorLink,
            adBackgroundColor,
            linkUnderlineColor,
            headingColor,
            headingTextStyle,
            contactColor,
            quoteColor,
            quoteBorderColor,
            quoteTextStyle,
            newsletterColor,
            newsletterIconComponent,
            relatedColor,
        }),
        [
            themeName,
            primaryColor,
            textColor,
            headerBackgroundColor,
            headerTextColor,
            headerTitleColor,
            headerBackgroundIsFull,
            headerImageIsFull,
            backgroundImage,
            backgroundRepeat,
            headerBackgroundIsBorder,
            headerImageTheme,
            withoutHeader,
            hideTitle,
            hideSubtitle,
            hideCategory,
            summaryPosition,
            metadataTextColor,
            metadataBackgroundColor,
            withoutAuthorLink,
            adBackgroundColor,
            linkUnderlineColor,
            headingColor,
            headingTextStyle,
            contactColor,
            quoteColor,
            quoteBorderColor,
            quoteTextStyle,
            newsletterColor,
            newsletterIconComponent,
            relatedColor,
        ],
    );

    const articleUniqueId = useMemo(() => `${article.id}-${new Date().getTime()}`, [article]);

    // Track clicks on external links
    const trackLink = useTrackLink();
    const onClickLink = useCallback(
        ({ url, external, label }) => {
            if (external) {
                trackLink(url, {
                    linkType: 'article',
                    linkLabel: label,
                });
            }
        },
        [trackLink],
    );
    const onClickBody = useOnClickLink(onClickLink);

    const { variant } = useSubscriptionVariant({
        subscription: null,
        variant: ({ id, author: variantAuthor = null }) =>
            id === `notification-${authorSlug}` ||
            (variantAuthor !== null && variantAuthor === authorSlug) ||
            (variantAuthor !== null && variantAuthor === authorId),
        placement: 'block',
        source: 'block',
    });
    const subscribed = useSubscribed(variant !== null ? variant.subscription : null);
    const [initialSubscribed] = useState(subscribed);

    const addonsOptions = useMemo(
        () => ({
            addons: [
                !withoutAds
                    ? {
                          block: 'ad',
                          max: 15,
                          interval: 4,
                          minHeight: 2,
                          minBlocks: 2,
                          notAfter: ['heading'],
                          notBefore: ['quote', 'image'],
                      }
                    : null,
                !initialSubscribed
                    ? {
                          block: 'subscription',
                          minHeight: Math.round(5 + Math.random() * 10),
                          notAfter: ['heading'],
                          notAround: ['quote', 'image', 'embed', 'ad', 'related'],
                          variant: ({ id, author: variantAuthor = null }) =>
                              id === `notification-${authorSlug}` ||
                              (variantAuthor !== null && variantAuthor === authorSlug) ||
                              (variantAuthor !== null && variantAuthor === authorId),
                      }
                    : null,
                {
                    block: 'related',
                    minHeight: Math.round(3 + Math.random() * 10),
                    notAfter: ['heading'],
                    notAround: ['quote', 'image', 'embed', 'subscription'],
                },
            ].filter((it) => it !== null),
        }),
        [articleUniqueId, initialSubscribed, withoutAds, authorSlug],
    );
    const blocksWithAddons = useBlocksWithAddons(blocks, addonsOptions);

    const hasSubscriptionBlock = useMemo(
        () =>
            (blocksWithAddons || []).reduce(
                (hasBlock, { type: blockType }) => hasBlock || blockType === 'subscription',
                false,
            ),
        [blocksWithAddons],
    );

    return (
        <ArticleThemeProvider theme={articleTheme}>
            <article
                className={classNames([
                    styles.container,
                    {
                        [styles.withoutSidebars]: withoutSidebars,
                        [styles.isArticle]: isArticle,
                        [styles.isFullscreen]: embedOnly && isEmbed,
                        [styles.newReaderDesign]: isReader,
                        [styles[`theme${pascalCase(themeName || '')}`]]:
                            typeof styles[`theme${pascalCase(themeName || '')}`] !== 'undefined',
                        [className]: className !== null,
                    },
                ])}
                style={{
                    color: textColor || null,
                    backgroundColor,
                    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
                    backgroundRepeat,
                }}
            >
                <LinkStyle
                    selector={[
                        `#article-${articleUniqueId}-body p`,
                        `#article-${articleUniqueId}-body li`,
                    ]}
                    underlineColor={linkUnderlineColor}
                />
                {!withoutHeader ? (
                    <>
                        <ArticleHeader
                            type={type}
                            article={article}
                            hideTitle={hideTitle}
                            hideSubtitle={hideSubtitle}
                            hideCategory={hideCategory}
                            withoutBackground={withoutHeaderBackground}
                            withoutColoredBackground={withoutHeaderColoredBackground}
                            linkUnderlineColor={linkUnderlineColor}
                            summaryPosition={summaryPosition}
                            imageTheme={headerImageTheme}
                            isFirst={infiniteScrollIndex === 0}
                            isReader={isReader}
                            embedOnly={embedOnly}
                            className={classNames([styles.header, { [styles.isReader]: isReader }])}
                        />

                        {!withoutTopAds && !withoutAds && (!isEmbed || !embedOnly) ? (
                            <Ad
                                slot="top"
                                disabled={adsDisabled}
                                alwaysRender
                                className={classNames([
                                    styles.topAd,
                                    {
                                        [styles.bgColorMatch]: adBackgroundColor !== null,
                                    },
                                ])}
                                emptyClassName={styles.empty}
                                backgroundColor={adBackgroundColor}
                            />
                        ) : null}

                        {!isEmbed || !embedOnly ? (
                            <div
                                className={styles.metadataContainer}
                                style={{
                                    backgroundColor: metadataBackgroundColor,
                                }}
                            >
                                <div className={styles.inner}>
                                    {!isReader ? (
                                        <ArticleMetadata
                                            className={styles.metadata}
                                            article={article}
                                            textColor={metadataTextColor}
                                            linkUnderlineColor={linkUnderlineColor}
                                            withoutAuthorLink={withoutAuthorLink}
                                            isCentered
                                            withCredits
                                            isReader={isReader}
                                        />
                                    ) : null}
                                    {withSponsors ? (
                                        <PresentedBy
                                            sponsors={sponsors}
                                            withLogos
                                            className={styles.sponsors}
                                            color={metadataTextColor}
                                            withoutBackgroundColor
                                        />
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}

                {!isEmbed || !embedOnly ? (
                    <div className={styles.inner}>
                        <div className={styles.body} onClick={onClickBody}>
                            <div id={`article-${articleUniqueId}-body`}>
                                <Blocks
                                    page={articleWithoutBlocks}
                                    blocks={withoutAddons ? blocks : blocksWithAddons}
                                    className={styles.blocks}
                                    adsDisabled={adsDisabled}
                                    withoutAds={withoutAds}
                                    withoutSidebars={withoutSidebars}
                                    isDark={isDark}
                                    withMaxWidth
                                />
                            </div>

                            {contest !== null ? (
                                <ContestBlock
                                    contest={contest}
                                    className={styles.endBlock}
                                    withMaxWidth
                                />
                            ) : null}

                            {displayMapLinks && locations !== null && locations.length > 0 ? (
                                <LocationsBlock
                                    locations={locations}
                                    className={styles.endBlock}
                                    withMaxWidth
                                />
                            ) : null}

                            {withNewsletter &&
                            !hasSubscriptionBlock &&
                            !initialSubscribed &&
                            contest === null ? (
                                <SubscriptionBlock
                                    className={styles.endBlock}
                                    withMaxWidth
                                    withoutContinue
                                />
                            ) : null}

                            {withContact ? (
                                <ContactBlock className={styles.endBlock} withMaxWidth />
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </article>
        </ArticleThemeProvider>
    );
}

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    Article,
    (
        {
            fonts: { otherTextStyle } = {},
            article: {
                useSectionColor = false,
                usePrimaryColorAsBackground = false,
                useQuoteColorAsBorder = true,
                headerTextColor,
                headerBackgroundIsBorder = false,
                headingColor,
                contactColor,
                quoteColor,
                quoteBorderColor,
                newsletterColor,
                backgroundColor = null,
                quoteTextStyle = null,
                relatedColor = null,
                summaryPosition: siteSummaryPosition = null,
                embedOnly = false,
                withoutAds,
                withoutAddons,
            } = {},
            newsletter: { iconComponent: newsletterIconComponent } = {},
            textColor,
            linkUnderlineColor,
            darkBackgroundColor,
        },
        {
            article: {
                type,
                metadata: {
                    color: articleColor = null,
                    section = null,
                    site = null,
                    theme: {
                        name: themeName = null,
                        headerBackgroundIsFull = false,
                        headerImageIsFull = false,
                        backgroundImage = null,
                        backgroundRepeat = null,
                        headerBackgroundColor = null,
                        headerTitleColor: articleHeaderTitleColor = null,
                        headerTextColor: articleHeaderTextColor = null,
                        textColor: articleTextColor = null,
                        backgroundColor: articleBackgroundColor = null,
                        quoteColor: articleQuoteColor = null,
                        headerImageTheme,
                        withoutHeader = false,
                        hideTitle = false,
                        hideSubtitle = false,
                        hideCategory = false,
                        summaryPosition = null,
                        metadataTextColor = null,
                        metadataBackgroundColor = null,
                        adBackgroundColor = null,
                        withoutAds: articleWithoutAds,
                        withoutAddons: articleWithoutAddons,
                        withoutSidebars = false,
                        withNewsletter = true,
                    } = {},
                } = {},
            } = {},
            withoutAds: propsWithoutAds = null,
            withoutAddons: propsWithoutAddons = null,
            hideCategory: propsHideCategory = null,
            layoutIsSmall = false,
        },
    ) => {
        const { color: sectionColor = null } = section || {};
        const sectionColorFaded = sectionColor !== null ? hexToRGB(sectionColor, 0.4) : null;
        const articleColorFaded = articleColor !== null ? hexToRGB(articleColor, 0.8) : null;
        const primaryColor = useSectionColor ? sectionColor || articleColor : articleColor;
        const isDehors = site !== null && site === 'dehors';

        const getArticleBackgroundColor = () => {
            if (articleBackgroundColor) {
                return articleBackgroundColor;
            }
            if (usePrimaryColorAsBackground) {
                return hexToRGB(primaryColor, 0.04);
            }
            if (backgroundColor) {
                return backgroundColor;
            }
            return null;
        };

        const getArticleTextColor = () => {
            if (articleTextColor) {
                return articleTextColor;
            }
            if (textColor) {
                return textColor;
            }
            return null;
        };

        const providedHeaderColor =
            articleHeaderTextColor || headerTextColor || (isDehors ? '#fffaf5' : null);

        return {
            // General
            themeName,
            primaryColor,
            textColor: getArticleTextColor(),
            backgroundColor: getArticleBackgroundColor(),
            linkUnderlineColor:
                (useSectionColor ? sectionColorFaded || articleColorFaded : articleColorFaded) ||
                linkUnderlineColor,

            // Header
            withoutHeader,
            hideTitle,
            hideSubtitle,
            hideCategory: propsHideCategory || hideCategory,
            summaryPosition: siteSummaryPosition || summaryPosition,
            headerImageTheme,
            headerBackgroundIsFull,
            headerImageIsFull,
            backgroundImage,
            backgroundRepeat,
            headerBackgroundIsBorder: headerBackgroundColor === null && headerBackgroundIsBorder,
            headerBackgroundColor:
                headerBackgroundColor || (type === 'video' ? darkBackgroundColor : primaryColor),
            headerTextColor:
                !layoutIsSmall &&
                providedHeaderColor === null &&
                !headerBackgroundIsBorder &&
                type !== 'video' &&
                !isDehors
                    ? getContrastingColor(primaryColor)
                    : providedHeaderColor,
            headerTitleColor: articleHeaderTitleColor,

            // Metadata
            metadataTextColor: metadataTextColor || getArticleTextColor(),
            metadataBackgroundColor,
            adBackgroundColor,

            // Blocks
            headingColor: headingColor || primaryColor,
            headingTextStyle: otherTextStyle,
            quoteColor: articleQuoteColor || quoteColor || primaryColor,
            quoteBorderColor:
                (useQuoteColorAsBorder ? articleQuoteColor || quoteColor : quoteBorderColor) ||
                primaryColor,
            quoteTextStyle,

            // Sections
            withNewsletter,
            contactColor: contactColor || primaryColor,
            newsletterColor: newsletterColor || primaryColor,
            newsletterIconComponent,

            relatedColor: relatedColor || primaryColor,

            // Toggle
            withoutAds: propsWithoutAds || articleWithoutAds || withoutAds,
            withoutAddons: propsWithoutAddons || articleWithoutAddons || withoutAddons,
            withoutSidebars,
            embedOnly,
        };
    },
);

export default WithThemeContainer;
