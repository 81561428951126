import Cookies from 'js-cookie';
import { useCallback, useState, useMemo } from 'react';

const isInteger = (value) =>
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value; // eslint-disable-line no-restricted-globals

const useHeaderAd = ({ key = null, onViewed = null, maxCount = 2 }) => {
    const name = useMemo(() => `ads_header_cookie_${key}`, [key]);
    const initialCount = useMemo(() => {
        const cookie = parseInt(Cookies.get(name), 10);
        if (isInteger(cookie)) {
            return cookie || 0;
        }
        return 0;
    }, []);

    const getViewedState = useCallback(
        (count) => {
            if (count >= maxCount) {
                return true;
            }
            return false;
        },
        [maxCount],
    );

    const [viewed, setViewedState] = useState(getViewedState(initialCount));

    const setViewed = useCallback(() => {
        const count = parseInt(Cookies.get(name), 10) || 0;
        const nextCount = count + 1;
        Cookies.set(name, nextCount);
        setViewedState(getViewedState(nextCount));
        if (onViewed !== null) {
            onViewed(name);
        }
    }, [name, onViewed, getViewedState, setViewedState]);

    return {
        viewed,
        setViewed,
    };
};

export default useHeaderAd;
