import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'wouter';

import useFonts from '../../hooks/useFonts';
import useLayoutMainMenu from '../../hooks/useLayoutMainMenu';

import { useUrlGenerator } from '@folklore/routes';
// import * as AppPropTypes from '../../lib/PropTypes';
import { useSite, withTheme } from '../../contexts/SiteContext';
import HeaderLogo from '../headers/HeaderLogo';
import MainMenu from '../menus/MainMenu';
import LoadingBar from '../partials/LoadingBar';
import ModalSafe from '../partials/ModalSafe';
import defaultFonts from './fonts';

import styles from '../../../styles/layouts/map.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    pageIsLoaded: PropTypes.bool,
    loadingColor: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    fonts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
};

const defaultProps = {
    pageIsLoaded: false,
    loadingColor: null,
    textColor: null,
    backgroundColor: null,
    fonts: defaultFonts,
};

function MapLayout({ fonts, children, pageIsLoaded, loadingColor, textColor, backgroundColor }) {
    const site = useSite();
    const [location] = useLocation();
    const generateUrl = useUrlGenerator();
    const finalFonts = useMemo(
        () =>
            site.id === 'quatre95'
                ? fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Apercu')
                : fonts.filter((it) => (Array.isArray(it) ? it[0] : it) !== 'Maax'),
        [fonts, site.id],
    );
    const { loaded: fontsLoaded } = useFonts(finalFonts);
    const {
        menuOpened,
        closeMenu,
        openMenu,
        onClickLightbox,
        toggleMenu,
        sectionsMenu,
        pagesMenus,
    } = useLayoutMainMenu(location);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.fontsLoaded]: fontsLoaded,
                },
            ])}
            style={{
                color: textColor,
            }}
        >
            <div className={styles.loading}>
                <LoadingBar loading={!pageIsLoaded} loaded={pageIsLoaded} color={loadingColor} />
            </div>
            <HeaderLogo
                href={generateUrl('home')}
                className={styles.header}
                toggleMenu={toggleMenu}
                color={backgroundColor}
            />
            <MainMenu
                sectionsMenu={sectionsMenu}
                pagesMenus={pagesMenus.items}
                opened={menuOpened}
                openMenu={openMenu}
                closeMenu={closeMenu}
                toggleMenu={toggleMenu}
                className={classNames([
                    styles.mainMenu,
                    {
                        [styles.opened]: menuOpened,
                    },
                ])}
            />
            <div className={styles.inner}>
                <div className={styles.content}>{children}</div>
            </div>
            <ModalSafe
                onClick={onClickLightbox}
                className={classNames([
                    styles.mainMenuBlock,
                    {
                        [styles.opened]: menuOpened,
                    },
                ])}
            />
        </div>
    );
}

MapLayout.propTypes = propTypes;
MapLayout.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    MapLayout,
    ({ textColor, backgroundColor, header: { loadingColor } = {} }) => ({
        loadingColor,
        textColor,
        backgroundColor,
    }),
);

export default WithThemeContainer;
