/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

import NewsletterPopup from './Newsletter';

const propTypes = {};

const defaultProps = {};

const NewsletterModalForm = (props) => <NewsletterPopup type="modal" withForm {...props} />;

NewsletterModalForm.propTypes = propTypes;
NewsletterModalForm.defaultProps = defaultProps;

export default NewsletterModalForm;
