import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MapPinIcon from './MapPin';

import styles from '../../../styles/icons/map-pin-circle.module.scss';

import image from '../../../img/maps/circle-pin-background.png';

const propTypes = {
    className: PropTypes.string,
    circleClassName: PropTypes.string,
};

const defaultProps = {
    className: null,
    circleClassName: null,
};

function MapPinCircleButton({ className, circleClassName }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={classNames([
                    styles.circle,
                    {
                        [circleClassName]: circleClassName !== null,
                    },
                ])}
            >
                <MapPinIcon className={styles.pin} />
                <img src={image} className={styles.image} alt="Carte" />
            </div>
        </div>
    );
}

MapPinCircleButton.propTypes = propTypes;
MapPinCircleButton.defaultProps = defaultProps;

export default MapPinCircleButton;
