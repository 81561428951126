export const messages = [
    {
        id: 'jour-de-paie',
        site: 'quatre95',
        icon: 'q95',
        title: 'Le jour de paie de votre inbox',
        callToAction: '→ Abonnez-vous à notre infolettre',
        backgroundColor: '#F04E3E',
        withInfolettreBackground: true,
    },
    {
        id: 'coucher-dehors',
        site: 'dehors',
        icon: 'instagram',
        title: 'Suivez nos histoires à coucher dehors',
        callToAction: '→ Abonnez-vous à notre Instagram',
        backgroundColor: '#F04E3E',
        url: 'https://www.instagram.com/dehors_ur/',
        callToActionColor: '#FFF0E0',
        textColor: '#FFF0E0',
        withSeasonBackground: true,
    },
    {
        id: 'deep-web',
        site: 'urbania',
        icon: 'deepWeb',
        title: 'Le secret le mieux gardé du Deep Web',
        callToAction: '→ C’est notre infolettre → Abonnez-vous',
        backgroundColor: '#00FF00',
    },
    {
        id: 'meilleur-urbania',
        site: 'urbania',
        icon: 'mailmain',
        title: 'Demandez le meilleur de URBANIA',
        callToAction: '→ Abonnez-vous à notre infolettre',
        backgroundColor: '#00FFB1',
    },
    {
        id: 'epice-secrete',
        site: 'urbania',
        icon: 'salt',
        title: 'L’épice secrète de ton&nbsp;inbox',
        callToAction: '→&nbsp;C’est notre infolettre →&nbsp;Abonnez-vous',
        backgroundColor: '#8C67FF',
    },
    {
        id: 'mon-corps',
        site: 'urbania',
        icon: 'jesus',
        title: 'Ceci est notre corps, livré pour vous',
        callToAction: '→ Abonnez-vous à notre infolettre',
        backgroundColor: '#009966',
    },
    {
        id: 'meilleur-urbania-fr',
        site: 'france',
        icon: 'mailmain',
        title: 'Demandez le meilleur de URBANIA',
        callToAction: '→ Abonnez-vous à notre infolettre',
        formLabel: 'Abonnez-vous à notre infolettre',
        backgroundColor: '#00FFB1',
    },
    {
        id: 'epice-secrete-fr',
        site: 'france',
        icon: 'salt',
        title: 'L’épice secrète de ton&nbsp;inbox',
        callToAction: '→ Abonnez-vous à notre infolettre',
        formLabel: 'Abonnez-vous à notre infolettre',
        backgroundColor: '#8C67FF',
    },
];

export default messages;
