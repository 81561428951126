/* eslint-disable react/jsx-props-no-spreading */
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useCallback } from 'react';

import useSubscribed from '../../hooks/useSubscribed';
import useSubscriptionVariant from '../../hooks/useSubscriptionVariant';

// import classNames from 'classnames';
import SubscriptionBaseModal from '../subscription/SubscriptionModal';
import Modal from './Modal';

const propTypes = {
    subscription: PropTypes.string,
    variant: PropTypes.string,
    closePopup: PropTypes.func.isRequired,
};

const defaultProps = {
    subscription: null,
    variant: null,
};

function SubscriptionModal({ closePopup, subscription, variant: providedVariant, ...props }) {
    const [opened, setOpened] = useState(false);
    const refContainer = useRef(null);
    const subscribed = useSubscribed();

    const { variant, markDone } = useSubscriptionVariant({
        subscription,
        variant: providedVariant,
        placement: 'modal',
        source: 'modal',
    });

    const onRequestClose = useCallback(() => {
        setOpened(false);
        if (markDone !== null) {
            markDone();
        }
    }, [setOpened, markDone]);

    const onComplete = useCallback(() => {
        setOpened(false);
    }, [setOpened]);

    useEffect(() => {
        if (!subscribed || process.env.NODE_ENV !== 'production') {
            setOpened(true);
        } else {
            closePopup();
        }
    }, [setOpened]);

    useEffect(() => {
        if (opened) {
            disableBodyScroll(refContainer.current);
        } else {
            enableBodyScroll(refContainer.current);
        }

        return () => {
            enableBodyScroll(refContainer.current);
        };
    }, [opened]);

    return (
        <Modal
            onClickSafe={onRequestClose}
            onClosed={closePopup}
            opened={opened}
            innerRef={refContainer}
        >
            <SubscriptionBaseModal
                {...variant}
                {...props}
                onClickClose={onRequestClose}
                onComplete={onComplete}
            />
        </Modal>
    );
}

SubscriptionModal.propTypes = propTypes;
SubscriptionModal.defaultProps = defaultProps;

export default SubscriptionModal;
