import { useRoutes, useUrlGenerator } from '@folklore/routes';
import { SocketContainer } from '@folklore/socket';
// import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useRef } from 'react';
import { Switch, useLocation, useSearch } from 'wouter';

// import * as AppPropTypes from '../lib/PropTypes';
import { useStatusCode, useResetNavigation } from '../contexts/NavigationContext';
import { useService } from '../contexts/ServicesContext';
import { useSite } from '../contexts/SiteContext';
import PageRoute from './PageRoute';
import * as layoutComponents from './layouts/index';
import { Home, Article, Page, Section } from './pages/index';

import '../../styles/main.scss';

import {
    ArticlePreview,
    Micromag,
    ErrorPage,
    Index,
    IndexItem,
    Collection,
    Magazine,
    Search,
    Quiz,
} from './pages/index.loadable';

const propTypes = {};

const defaultProps = {};

function App() {
    const statusCode = useStatusCode();
    const resetNavigation = useResetNavigation();
    const routes = useRoutes();
    const generateUrl = useUrlGenerator();
    const { indexes = [] } = useSite();
    const [pathname] = useLocation();
    const search = useSearch();
    const pageUrl = pathname !== '/' ? pathname.replace(/\/$/, '') : pathname;
    const isInitialPageview = useRef(true);
    const firstUrl = useRef(pageUrl);
    const { namespace: pubnubNamespace, subscribeKey: pubnubSubscribeKey } = useService('pubnub');

    if (isInitialPageview.current && firstUrl.current !== pageUrl) {
        isInitialPageview.current = false;
    }

    // Reset request on history change
    useEffect(() => () => resetNavigation(), [pathname]);

    return (
        <Switch>
            <PageRoute
                path={routes.home}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => <Home page={page} />}
            />
            <PageRoute
                path={routes['submissions.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { submission = null } }) => (
                    <Page
                        page={page}
                        pageUrl={generateUrl('submissions.show.json', {
                            submission,
                        })}
                    />
                )}
            />
            <PageRoute
                path={routes['submissions.page']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { submission_page: submissionPageSlug = null } }) => (
                    <Page
                        page={page}
                        pageUrl={generateUrl('submissions.page.json', {
                            submission_page: submissionPageSlug,
                        })}
                    />
                )}
            />
            <PageRoute
                path={routes.submissions}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => (
                    <Page page={page} pageUrl={generateUrl('submissions.json')} />
                )}
            />
            <PageRoute
                path={routes['subscriptions.manage']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => <Page page={page} />}
            />
            <PageRoute
                path={routes['subscriptions.profile']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => <Page page={page} />}
            />
            <PageRoute
                path={routes['auth.login']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => <Page page={page} />}
            />
            <PageRoute
                path={routes['games.questions.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { game: gameSlug } }) => (
                    <SocketContainer namespace={pubnubNamespace} subscribeKey={pubnubSubscribeKey}>
                        <Page
                            page={page}
                            pageUrl={generateUrl('games.questions.show.json', {
                                game: gameSlug,
                            })}
                        />
                    </SocketContainer>
                )}
            />
            <PageRoute
                path={routes['games.questions']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => (
                    <SocketContainer namespace={pubnubNamespace} subscribeKey={pubnubSubscribeKey}>
                        <Page page={page} pageUrl={generateUrl('games.questions.json')} />
                    </SocketContainer>
                )}
            />
            <PageRoute
                path={routes.games}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => (
                    <SocketContainer namespace={pubnubNamespace} subscribeKey={pubnubSubscribeKey}>
                        <Page page={page} pageUrl={generateUrl('games.questions.json')} />
                    </SocketContainer>
                )}
            />
            <PageRoute
                path={routes['games.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { game: gameSlug } }) => (
                    <SocketContainer namespace={pubnubNamespace} subscribeKey={pubnubSubscribeKey}>
                        <Page
                            page={page}
                            pageUrl={generateUrl('games.show.json', {
                                game: gameSlug,
                            })}
                        />
                    </SocketContainer>
                )}
            />
            <PageRoute
                path={routes.search}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={() => {
                    const { q: searchQuery = null } = queryString.parse(search);
                    return search !== null && searchQuery !== null
                        ? `${pageUrl}?q=${encodeURIComponent(searchQuery)}`
                        : pageUrl;
                }}
                renderPage={(page) => {
                    const { q: searchQuery = null } = queryString.parse(search);
                    return <Search search={page} query={searchQuery} />;
                }}
            />
            <PageRoute
                path={routes['articles.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { article: articleSlug } }) => (
                    <Article article={page} articleSlug={articleSlug} />
                )}
            />
            <PageRoute
                path={routes['micromags.stats']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { micromag } }) =>
                    generateUrl('micromags.stats', {
                        micromag,
                    })
                }
                renderPage={(page, { params: { page: pageSlug } }) => (
                    <SocketContainer namespace={pubnubNamespace} subscribeKey={pubnubSubscribeKey}>
                        <Page page={page} pageSlug={pageSlug} />
                    </SocketContainer>
                )}
            />
            {/* <PageRoute
                path={routes['micromags.show']}
                isInitialPageview={isInitialPageview.current}
                layout="micromag"
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { micromag } }) =>
                    generateUrl('micromags.show', {
                        micromag,
                    })
                }
                renderPage={(
                    page,
                    { params: { micromag: micromagSlug, micromag_path: micromagPath = null } },
                ) => (
                    <Micromag
                        micromag={page}
                        micromagSlug={micromagSlug}
                        micromagPath={micromagPath}
                    />
                )}
            /> */}

            <PageRoute
                path={routes['micromags.show.wildcard']}
                isInitialPageview={isInitialPageview.current}
                layout="micromag"
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { micromag, micromag_path: micromagPath = null } }) =>
                    generateUrl('micromags.show.wildcard', {
                        micromag,
                        micromag_path: micromagPath,
                    })
                }
                renderPage={(
                    page,
                    { params: { micromag: micromagSlug, micromag_path: micromagPath = null } },
                ) => (
                    <Micromag
                        micromag={page}
                        micromagSlug={micromagSlug}
                        micromagPath={micromagPath}
                    />
                )}
            />
            <PageRoute
                path={routes['articles.preview']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { preview_article: articleSlug } }) => (
                    <ArticlePreview article={page} articleSlug={articleSlug} />
                )}
            />
            <PageRoute
                path={routes['quiz.show.slide']}
                isInitialPageview={isInitialPageview.current}
                layout="reader"
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { quiz: quizSlug = null } }) =>
                    generateUrl('quiz.show', {
                        quiz: quizSlug,
                    })
                }
                renderPage={(
                    page,
                    { params: { quiz: quizSlug = null, slide: quizSlide = null } },
                ) => <Quiz page={page} slug={quizSlug} slide={quizSlide} />}
            />
            <PageRoute
                path={routes['quiz.show']}
                isInitialPageview={isInitialPageview.current}
                layout="reader"
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { quiz: quizSlug = null } }) =>
                    generateUrl('quiz.show', {
                        quiz: quizSlug,
                    })
                }
                renderPage={(page, { params: { quiz: quizSlug = null } }) => (
                    <Quiz page={page} slug={quizSlug} />
                )}
            />
            <PageRoute
                path={routes.index}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { index: indexSlug } }) => (
                    <Index index={page} indexSlug={indexSlug} />
                )}
            />
            <PageRoute
                path={routes['index.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { index: indexSlug, item: itemSlug } }) => {
                    const index = indexes.find((it) => it.slug === indexSlug) || null;
                    return <IndexItem index={index} item={page} itemSlug={itemSlug} />;
                }}
            />
            <PageRoute
                path={routes['collections.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { collection: collectionSlug } }) => (
                    <Collection collection={page} collectionSlug={collectionSlug} />
                )}
            />
            <PageRoute
                path={routes.collections}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => <Collection collection={page} />}
            />
            <PageRoute
                path={routes['magazines.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { magazine: magazineSlug } }) => (
                    <Magazine magazine={page} magazineSlug={magazineSlug} />
                )}
            />
            <PageRoute
                path={routes.magazines}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page) => <Magazine magazine={page} />}
            />
            <PageRoute
                path={routes['sections.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { section: sectionSlug } }) => (
                    <Section section={page} sectionSlug={sectionSlug} />
                )}
            />
            <PageRoute
                path={routes['sections.category.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(
                    page,
                    { params: { section: sectionSlug, category: categorySlug } },
                ) => (
                    <Section section={page} sectionSlug={sectionSlug} categorySlug={categorySlug} />
                )}
            />
            <PageRoute
                path={routes['maps.season.location_type']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { location_type: locationType = null, season = null } }) =>
                    generateUrl('maps.season.location_type', {
                        season,
                        location_type: locationType,
                    })
                }
                renderPage={(
                    page,
                    { params: { location_type: locationType = null, season = null } },
                ) => (
                    <Page
                        page={page}
                        pageUrl={generateUrl('maps.season.location_type.json', {
                            season,
                            location_type: locationType,
                        })}
                        locationType={locationType}
                        season={season}
                    />
                )}
            />
            <PageRoute
                path={routes['maps.season']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { season = null } }) =>
                    generateUrl('maps.season', {
                        season,
                    })
                }
                renderPage={(page, { params: { season = null } }) => (
                    <Page
                        page={page}
                        pageUrl={generateUrl('maps.season.json', {
                            season,
                        })}
                        season={season}
                    />
                )}
            />
            <PageRoute
                path={routes['maps.location_type']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { location_type: locationType = null } }) =>
                    generateUrl('maps.location_type', {
                        location_type: locationType,
                    })
                }
                renderPage={(page, { params: { location_type: locationType = null } }) => (
                    <Page
                        page={page}
                        pageUrl={generateUrl('maps.location_type.json', {
                            location_type: locationType,
                        })}
                        locationType={locationType}
                    />
                )}
            />
            <PageRoute
                path={routes['maps.location']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={({ params: { location = null } }) =>
                    generateUrl('maps.location', {
                        location,
                    })
                }
                renderPage={(page, { params: { location = null } }) => (
                    <Page
                        page={page}
                        pageUrl={generateUrl('maps.location.json', {
                            location,
                        })}
                        location={location}
                    />
                )}
            />
            <PageRoute
                path={routes.maps}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                getPageUrl={() => generateUrl('maps')}
                renderPage={(page) => <Page page={page} pageUrl={generateUrl('maps.json')} />}
            />
            <PageRoute
                path={routes['pages.show']}
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={(page, { params: { page: pageSlug } }) => (
                    <SocketContainer namespace={pubnubNamespace} subscribeKey={pubnubSubscribeKey}>
                        <Page page={page} pageSlug={pageSlug} withoutUrl />
                    </SocketContainer>
                )}
            />
            <PageRoute
                path="*"
                withoutErrorPage
                isInitialPageview={isInitialPageview.current}
                layoutComponents={layoutComponents}
                renderPage={() => <ErrorPage statusCode={statusCode} />}
            />
        </Switch>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
