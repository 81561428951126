/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { withTheme } from '../../contexts/SiteContext';
import * as LogoComponents from '../icons/logos/monochrome';
import Link from '../partials/Link';

import styles from '../../../styles/menus/sites.module.scss';

const propTypes = {
    sites: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            url: PropTypes.string,
        }),
    ),
    iconColor: AppPropTypes.color,
    backgroundColor: AppPropTypes.color,
    visible: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    sites: [
        {
            icon: 'Urbania',
            url: 'https://urbania.ca',
        },
        // {
        //     icon: 'UrbaniaMusique',
        //     url: 'https://urbania.ca/sujets/musique-culture',
        // },
        {
            icon: 'Quatre95',
            url: 'https://quatre95.urbania.ca',
        },
        {
            icon: 'Universites',
            url: 'https://universites.urbania.ca',
        },
        {
            icon: 'Dehors',
            url: 'https://dehors.urbania.ca',
        },
        {
            icon: 'Mollo',
            url: 'https://mollo.urbania.ca',
        },
    ],
    visible: true,
    iconColor: null,
    backgroundColor: null,
    className: null,
};

const SitesMenu = ({ sites, visible, iconColor, backgroundColor, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.hidden]: !visible,
                [className]: className !== null,
            },
        ])}
        style={{ color: iconColor, backgroundColor }}
    >
        {sites.map(({ icon, url = null }) => {
            const LogoComponent = LogoComponents[icon] || null;
            return LogoComponent !== null ? (
                <div className={styles.site} key={`logo-${url || icon}`}>
                    <Link className={styles.logo} href={url || '/'} external={url !== null}>
                        <LogoComponent backgroundColor={backgroundColor} />
                    </Link>
                </div>
            ) : null;
        })}
    </div>
);

SitesMenu.propTypes = propTypes;
SitesMenu.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    SitesMenu,
    ({ sitesBar: { iconColor, backgroundColor, visible = true } = {} }) => ({
        iconColor,
        backgroundColor,
        visible,
    }),
);

export default WithThemeContainer;
