import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import useSubscribed from '../../hooks/useSubscribed';

import CloseCircleButton from '../buttons/CloseCircle';
import EvilZuckerbergLink from '../partials/EvilZuckerbergLink';
import NewsletterBox from './SubscriptionBox';
import NewsletterButton from './SubscriptionButton';
import SubscriptionForm from './SubscriptionForm';
import NewsletterIcon from './SubscriptionIcon';
import NewsletterText from './SubscriptionText';

import styles from '../../../styles/subscription/modal.module.scss';

const propTypes = {
    variant: PropTypes.string,
    subscription: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.string,
    icon: PropTypes.string,
    instagram: PropTypes.string,
    onClickClose: PropTypes.func,
    onComplete: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    variant: null,
    subscription: null,
    title: 'Abonnez-vous à l’infolettre URBANIA',
    description: 'Vous êtes spécial, notre infolettre l’est aussi',
    theme: 'dehorsGradient',
    icon: 'dehorsWeather',
    instagram: null,
    onClickClose: null,
    onComplete: null,
    className: null,
};

function SubscriptionModal({
    variant,
    subscription,
    title,
    description,
    icon,
    instagram,
    theme,
    onClickClose,
    onComplete,
    className,
}) {
    const onCompleteForm = useCallback(() => {
        setTimeout(() => {
            onComplete();
        }, 3500);
    }, [onComplete]);

    const subscribed = useSubscribed(subscription);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withButton]: instagram !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <NewsletterBox theme={theme} className={styles.box}>
                    <CloseCircleButton onClick={onClickClose} className={styles.closeButton} />
                    <NewsletterIcon
                        name={icon}
                        className={styles.icon}
                        subscribed={subscribed}
                        placement="modal"
                        onClickClose={onClickClose}
                    />
                    <div className={styles.content}>
                        <NewsletterText
                            title={title}
                            description={description}
                            theme={theme}
                            className={styles.text}
                        />
                        <div className={styles.action}>
                            {instagram !== null ? (
                                <NewsletterButton
                                    theme={theme}
                                    href={`https://instagram.com/${instagram}`}
                                    external
                                >
                                    <FormattedMessage
                                        defaultMessage="Suivez-nous sur instagram"
                                        description="Button label"
                                    />
                                </NewsletterButton>
                            ) : (
                                <SubscriptionForm
                                    subscription={subscription}
                                    theme={theme}
                                    source={variant !== null ? `modal:${variant}` : 'modal'}
                                    className={styles.form}
                                    onDismiss={onClickClose}
                                    onComplete={onCompleteForm}
                                />
                            )}
                        </div>
                        {theme === 'evil' ? (
                            <EvilZuckerbergLink className={styles.evil} onClose={onClickClose} />
                        ) : null}
                    </div>
                </NewsletterBox>
            </div>
        </div>
    );
}

SubscriptionModal.propTypes = propTypes;
SubscriptionModal.defaultProps = defaultProps;

export default SubscriptionModal;
