/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PresentedBy from './PresentedBy';
import Embed from './Embed';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/video.module.scss';

const propTypes = {
    video: AppPropTypes.video,
    autoPlay: PropTypes.bool,
    presentedBy: AppPropTypes.sponsors,
    withLazyLoading: PropTypes.bool,
    fullscreen: PropTypes.bool,
    className: PropTypes.string,
    embedClassName: PropTypes.string,
};

const defaultProps = {
    video: {},
    autoPlay: false,
    presentedBy: null,
    withLazyLoading: false,
    fullscreen: false,
    className: null,
    embedClassName: null,
};

function Video({
    video: { id, provider, embed, width, height, title },
    autoPlay,
    presentedBy,
    withLazyLoading,
    fullscreen,
    className,
    embedClassName,
}) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isFullscreen]: fullscreen,
                    [className]: className !== null,
                },
            ])}
        >
            <Embed
                id={id}
                title={title}
                type="video"
                provider={provider}
                url={embed}
                width={width}
                height={height}
                autoPlay={autoPlay}
                fullscreen={fullscreen}
                withLazyLoading={withLazyLoading}
                className={classNames([
                    styles.embed,
                    {
                        [embedClassName]: embedClassName !== null,
                    },
                ])}
            />
            {presentedBy !== null ? (
                <PresentedBy className={styles.presentedBy} sponsors={presentedBy} />
            ) : null}
        </div>
    );
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
