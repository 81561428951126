/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import { useUrlGenerator } from '@folklore/routes';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import useSwipe from '../../hooks/useSwipe';
import { useTrackOnClickLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName, hexToRGB } from '../../lib/utils';

import { withTheme } from '../../contexts/SiteContext';
import BurgerButton from '../buttons/Burger';
import Search from '../forms/Search';
import * as logoComponents from '../icons/logos/index';
import SmallRow from '../lists/SmallRow';
import Link from '../partials/Link';
// import Label from '../partials/Label';
import LinkStyle from '../partials/LinkStyle';
import Menu from './Menu';

import styles from '../../../styles/menus/main-menu-default.module.scss';

const propTypes = {
    logoComponent: PropTypes.string,
    sectionsMenu: AppPropTypes.menu,
    pagesMenus: PropTypes.arrayOf(AppPropTypes.menu),
    opened: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    textColor: AppPropTypes.color,
    linkUnderlineColor: AppPropTypes.color,
    backgroundColor: AppPropTypes.color,
    searchBackgroundColor: AppPropTypes.color,
    linksMenuColor: AppPropTypes.color,
    borderColor: AppPropTypes.color,
    className: PropTypes.string,
};

const defaultProps = {
    logoComponent: null,
    sectionsMenu: [],
    pagesMenus: [],
    textColor: null,
    linkUnderlineColor: null,
    backgroundColor: null,
    searchBackgroundColor: null,
    linksMenuColor: null,
    borderColor: null,
    className: null,
};

export const MainMenuDefault = ({
    logoComponent,
    sectionsMenu,
    pagesMenus,
    opened,
    closeMenu,
    toggleMenu,
    textColor,
    linkUnderlineColor,
    backgroundColor,
    searchBackgroundColor,
    linksMenuColor,
    borderColor,
    className,
}) => {
    const refContainer = useRef(null);
    const searchInputRef = useRef(null);
    const menusRef = useRef(null);
    const generateUrl = useUrlGenerator();
    const [, setLocation] = useLocation();
    const [searchOpened, setSearchOpened] = useState(false);
    const onClickPopularItem = useTrackOnClickLink('click_menu_popular');
    const onClickMenuItem = useTrackOnClickLink('click_menu_item');
    const LogoComponent = getComponentFromName(logoComponents, logoComponent, 'urbania');

    useEffect(() => {
        const { current: containerElement = null } = refContainer;
        if (opened) {
            disableBodyScroll(containerElement);
        } else {
            enableBodyScroll(containerElement);
            if (searchOpened) {
                setSearchOpened(false);
            }
        }
        return () => {
            enableBodyScroll(containerElement);
        };
    }, [opened]);

    const onSearchSubmit = useCallback(
        (searchingQuery) => {
            setLocation(`${generateUrl('search')}?q=${encodeURIComponent(searchingQuery)}`);
            closeMenu();
        },
        [closeMenu, setLocation, generateUrl],
    );

    const onMenuClick = useCallback(() => toggleMenu(), [toggleMenu]);

    const onSwipeRight = useCallback(() => {
        if (opened) {
            closeMenu();
        }
    }, [opened, closeMenu]);
    const bindSwipe = useSwipe({
        onSwipeRight,
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened,
                    [styles.searchOpened]: searchOpened,
                    [className]: className !== null,
                },
            ])}
            style={{ backgroundColor, color: textColor, borderColor }}
            {...bindSwipe()}
        >
            <div className={styles.top}>
                <div className={styles.bar}>
                    <Link className={styles.logoLink} href="/" onClick={onMenuClick}>
                        <LogoComponent className={styles.logo} />
                    </Link>
                    <BurgerButton
                        className={styles.burger}
                        color={textColor}
                        menuOpened={opened}
                        onClick={onMenuClick}
                    />
                </div>
            </div>
            <div
                className={styles.search}
                style={{ color: textColor, backgroundColor: searchBackgroundColor }}
            >
                <Search
                    className={styles.form}
                    inputClassName={styles.input}
                    inputRef={searchInputRef}
                    onSubmit={onSearchSubmit}
                />
            </div>

            <div className={styles.popular} style={{ borderColor }}>
                <div
                    className={styles.fadeLeft}
                    style={{
                        backgroundImage: `linear-gradient(90deg, ${backgroundColor} 10%, ${hexToRGB(
                            backgroundColor,
                            0,
                        )} 90%)`,
                    }}
                />
                <SmallRow
                    url={generateUrl('popular.json')}
                    withoutIcons
                    className={styles.list}
                    itemClassName={styles.item}
                    thumbnailClassName={styles.thumbnail}
                    maxLabelLength={30}
                    onClickItem={onClickPopularItem}
                />
                <div
                    className={styles.fadeRight}
                    style={{
                        backgroundImage: `linear-gradient(90deg, ${hexToRGB(
                            backgroundColor,
                            0,
                        )} 10%, ${backgroundColor} 90%)`,
                    }}
                />
            </div>
            <div ref={menusRef} className={styles.menus}>
                <LinkStyle
                    selector={`.${styles.menus}`}
                    linkSelector={`.${styles.link}`}
                    underlineColor={linkUnderlineColor}
                    borderBottom
                />
                <div className={styles.mainMenu}>
                    <Menu
                        items={sectionsMenu.items}
                        itemClassName={styles.item}
                        linkClassName={styles.link}
                        hasSubMenuClassName={styles.hasSubMenu}
                        subMenuClassName={styles.subMenu}
                        onClickItem={onClickMenuItem}
                    />
                </div>
                <div
                    className={styles.linksMenu}
                    style={{
                        backgroundColor: linksMenuColor,
                        borderTopColor: borderColor,
                    }}
                >
                    <div className={styles.columns}>
                        {pagesMenus.map((menu, index) => (
                            <div
                                key={`links-menu-${index}`}
                                className={styles.menu}
                                style={{
                                    borderLeftColor: borderColor,
                                }}
                            >
                                <Menu
                                    items={menu.items}
                                    subMenuClassName={styles.subMenu}
                                    itemClassName={styles.item}
                                    linkClassName={styles.link}
                                    labelClassName={styles.label}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

MainMenuDefault.propTypes = propTypes;
MainMenuDefault.defaultProps = defaultProps;

export default withTheme(
    MainMenuDefault,
    ({
        mainMenu: {
            logoComponent,
            textColor,
            linkUnderlineColor,
            backgroundColor,
            searchBackgroundColor,
            linksMenuColor,
            borderColor,
        } = {},
    }) => ({
        logoComponent,
        textColor,
        linkUnderlineColor,
        backgroundColor,
        searchBackgroundColor,
        linksMenuColor,
        borderColor,
    }),
);
