import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Quatre95Mono = ({ color, className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="53px"
        height="36.4px"
        viewBox="0 0 53 36.4"
        className={className}
    >
        <path
            fill={color}
            d="M29.8 14.9c-.4-.4-1-.6-1.5-.5-.5.1-1 .3-1.3.7-.3.4-.5 1-.4 1.7.1.6.3 1.1.8 1.5.4.3.9.5 1.5.4.6-.1 1-.3 1.4-.8.3-.4.5-1 .4-1.5-.2-.6-.5-1.1-.9-1.5"
        />
        <path
            fill={color}
            d="M52.9 15.6c-.7-8.1-6-7.5-6.7-15.6L3.9 4.1c.7 8.1-4.6 8.6-3.9 16.6.7 8.1 6 7.5 6.7 15.6L49 32.2c-.7-8 4.6-8.5 3.9-16.6m-34.5 8.6l-1 .1-.3-2.3-4.3.5-.1-.9 3.7-6.6.9-.1.6 6.1 1.3-.1.1 1-1.3.1.4 2.2zm3.8 1l-.8-.6 2.4-3 .8.6-2.4 3zm9.4-3.6c-.5.9-1.3 1.4-2.5 1.6-1 .1-1.9-.2-2.7-.9l.6-.8c.5.6 1.2.9 2 .8.8-.1 1.4-.5 1.8-1.3.4-.8.5-1.8.3-2.9-.4 1-1.3 1.6-2.3 1.7-.8.1-1.6-.1-2.2-.6-.7-.5-1-1.2-1.1-2.2-.1-1 .1-1.7.7-2.4.6-.6 1.2-1 2-1.1 1-.1 1.9.3 2.6 1.1.7.8 1.2 2 1.3 3.5.1 1.4 0 2.6-.5 3.5m8.2-.5c-.5.7-1.3 1.1-2.3 1.2-1.1.1-2.2-.2-3-1.1l.6-.8c.7.7 1.5 1 2.3.9.6-.1 1.1-.3 1.5-.8.4-.4.6-1 .5-1.6-.1-1.3-1.1-2-2.3-1.8-.8.1-1.5.6-1.7 1.2l-.9.1-.3-5.5 5-.6.1 1-4 .5.2 3.2c.3-.5 1-.8 1.8-.9.8-.1 1.5.1 2.2.6.6.5 1 1.2 1.1 2.1 0 .8-.3 1.6-.8 2.3"
        />
        <path fill={color} d="M13.8 21.3L17 21l-.4-4.5z" />
    </svg>
);

Quatre95Mono.propTypes = propTypes;
Quatre95Mono.defaultProps = defaultProps;

export default Quatre95Mono;
