/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '../../lib/utils';

import { withTheme } from '../../contexts/SiteContext';
import WithIcon from '../icons/With';
import Urbania from '../icons/logos/Urbania';
import * as Sponsors from '../icons/sponsors';

import styles from '../../../styles/partials/site-sponsor.module.scss';

const propTypes = {
    component: PropTypes.string,
    url: PropTypes.string,
    withUrbania: PropTypes.bool,
    monochrome: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    logoClassName: PropTypes.string,
};

const defaultProps = {
    component: null,
    url: null,
    withUrbania: true,
    monochrome: false,
    color: null,
    className: null,
    logoClassName: null,
};

const SiteSponsor = ({
    component,
    url,
    withUrbania,
    color,
    monochrome,
    className,
    logoClassName,
}) => {
    const SponsorComponent = getComponentFromName(Sponsors, component, null);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ color }}
        >
            {withUrbania ? (
                <>
                    <a
                        className={styles.link}
                        href="https://urbania.ca"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Urbania
                            className={classNames([
                                styles.logo,
                                {
                                    [logoClassName]: logoClassName !== null,
                                },
                            ])}
                        />
                    </a>
                    {SponsorComponent !== null ? (
                        <div className={styles.with}>
                            <WithIcon />
                        </div>
                    ) : null}
                </>
            ) : null}
            {SponsorComponent !== null ? (
                <a className={styles.link} href={url} target="_blank" rel="noopener noreferrer">
                    <SponsorComponent
                        className={classNames([
                            styles.logo,
                            styles.sponsor,
                            {
                                [logoClassName]: logoClassName !== null,
                            },
                        ])}
                        monochrome={monochrome}
                    />
                </a>
            ) : null}
        </div>
    );
};

SiteSponsor.propTypes = propTypes;
SiteSponsor.defaultProps = defaultProps;

export default withTheme(SiteSponsor, ({ sponsor: { component, url } = {} }) => ({
    component,
    url,
}));
