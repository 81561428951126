/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/popups/banner.module.scss';

const propTypes = {
    position: PropTypes.oneOf(['auto', 'bottom']),
    className: PropTypes.string,
    opened: PropTypes.bool,
    onClosed: PropTypes.func,
    backgroundColor: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    position: 'auto',
    className: null,
    opened: false,
    onClosed: null,
    backgroundColor: null,
    children: null,
};

function Banner({ position, className, opened, onClosed, backgroundColor, children }) {
    const onTransitionEnd = useCallback(
        () => {
            if (!opened && onClosed !== null) {
                onClosed();
            }
        },
        [opened, onClosed],
    );
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened,
                    [styles.bottom]: position === 'bottom',
                    [className]: className !== null,
                },
            ])}
            style={{ backgroundColor }}
            onTransitionEnd={onTransitionEnd}
        >
            {children}
        </div>
    );
};

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;

export default Banner;
