import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useSection } from '../../hooks/usePage';
import useScrollToTop from '../../hooks/useScrollToTop';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSetPage } from '../../contexts/NavigationContext';
import { useUrlGenerator } from '@folklore/routes';
import { withTheme } from '../../contexts/SiteContext';
import SectionHeader from '../headers/SectionHeader';
import ArticlesGrid from '../lists/ArticlesGrid';
import SectionMeta from '../meta/SectionMeta';
import Ad from '../partials/Ad';

import styles from '../../../styles/pages/section.module.scss';

const propTypes = {
    section: AppPropTypes.section,
    sectionSlug: PropTypes.string.isRequired,
    categorySlug: PropTypes.string,
    query: PropTypes.shape({
        type: PropTypes.string,
        section: PropTypes.string,
    }),
    darkBackgroundColor: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    section: null,
    categorySlug: null,
    query: {},
    isDisabled: false,
    onMetadataChange: null,
};

function SectionPage({
    section,
    sectionSlug,
    categorySlug,
    darkBackgroundColor,
    isDisabled,
    onMetadataChange,
}) {
    const setPage = useSetPage();
    const generateUrl = useUrlGenerator();
    useScrollToTop(section, sectionSlug);

    useSection({
        slug: sectionSlug,
        section,
        category: categorySlug,
        onLoad: setPage,
    });
    const componentsProps = useMemo(
        () => ({
            includeCategories:
                section !== null ? (section.categories || []).map(({ slug }) => slug) : null,
            excludeCategories: section !== null ? [section.id] : null,
            withCategory: section !== null,
            withDate: true,
            withAvatar: false,
        }),
        [section],
    );
    if (section === null) {
        return null;
    }
    const isDark = section !== null && section.id === 'videos';
    const articlesUrl = categorySlug
        ? generateUrl('sections.category.list.json', {
              section: sectionSlug,
              category: categorySlug,
          })
        : generateUrl('sections.list.json', {
              section: sectionSlug,
          });

    return (
        <main
            className={styles.container}
            style={{
                backgroundColor: isDark ? darkBackgroundColor : null,
            }}
        >
            {!isDisabled ? <SectionMeta section={section} onChange={onMetadataChange} /> : null}
            <SectionHeader section={section} category={categorySlug} />
            <Ad isDark={isDark} slot="top" alwaysRender />
            <div className={styles.inner}>
                <ArticlesGrid
                    url={articlesUrl}
                    initialPages={section.pages}
                    componentProps={componentsProps}
                    isDark={isDark}
                    moreClassName={styles.more}
                />
            </div>
        </main>
    );
}

SectionPage.propTypes = propTypes;
SectionPage.defaultProps = defaultProps;

const WithThemeContainer = withTheme(SectionPage, ({ darkBackgroundColor }) => ({
    darkBackgroundColor,
}));

export default WithThemeContainer;
