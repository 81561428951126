/* eslint-disable jsx-a11y/control-has-associated-label, react/no-danger */
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { getTodaySeason } from '../../lib/utils';

import { useSite } from '../../contexts/SiteContext';
import Button from '../buttons/Button';
import CloseCircleButton from '../buttons/CloseCircle';
import SubscriptionField from '../subscription/SubscriptionField';
import Banner from './Banner';
import Modal from './Modal';
import NewsletterFormModal from './NewsletterFormModal';
import allMessages from './messages';

import styles from '../../../styles/popups/newsletter.module.scss';

import aubergineIcon from '../../../img/newsletter-icons/aubergine.png';
import deepWebIcon from '../../../img/newsletter-icons/deepweb.svg';
import instagramIcon from '../../../img/newsletter-icons/instagram.svg';
import jesusIcon from '../../../img/newsletter-icons/jesus.png';
import mailmanIcon from '../../../img/newsletter-icons/mailman.png';
import q95Icon from '../../../img/newsletter-icons/q95.svg';
import saltIcon from '../../../img/newsletter-icons/salt.png';

const propTypes = {
    type: PropTypes.oneOf(['modal', 'banner']),
    // eslint-disable-next-line react/forbid-prop-types
    messages: PropTypes.arrayOf(PropTypes.object),
    icons: PropTypes.objectOf(PropTypes.string),
    closePopup: PropTypes.func.isRequired,
    closeAfterSuccess: PropTypes.number,
    className: PropTypes.string,
    withForm: PropTypes.bool,
};

const defaultProps = {
    type: 'modal',
    messages: allMessages,
    icons: {
        q95: (
            <img
                src={q95Icon}
                alt="Quatre95"
                className={classNames([styles.icon, styles.margin])}
            />
        ),
        aubergine: <img src={aubergineIcon} alt="Aubergine" className={styles.icon} />,
        instagram: (
            <img
                src={instagramIcon}
                alt="Instagram"
                className={classNames([styles.icon, styles.square])}
            />
        ),
        deepWeb: (
            <img
                src={deepWebIcon}
                alt="Code binaire"
                className={classNames([styles.icon, styles.square])}
            />
        ),
        jesus: <img src={jesusIcon} alt="Jésus" className={styles.icon} />,
        mailmain: <img src={mailmanIcon} alt="Facteur" className={styles.icon} />,
        salt: <img src={saltIcon} alt="Salt" className={styles.icon} width="220" height="240" />,
    },
    closeAfterSuccess: 1000,
    withForm: false,
    className: null,
};

function NewsletterPopup({
    type,
    messages,
    icons,
    closePopup,
    closeAfterSuccess,
    withForm,
    className,
}) {
    const { id: siteId } = useSite();

    const siteMessages = messages.filter(({ site = null }) => site === null || site === siteId);
    const hasMessages = siteMessages.length > 0;
    const [
        {
            // id,
            icon: iconId,
            title,
            callToAction,
            formLabel = null,
            backgroundColor = null,
            textColor = null,
            callToActionColor = null,
            withInfolettreBackground = false,
            withSeasonBackground = false,
            url = null,
        },
    ] = useState(hasMessages ? siteMessages[Math.floor(Math.random() * siteMessages.length)] : {});
    const season = siteId === 'dehors' ? getTodaySeason() : null;
    const [opened, setOpened] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [success, setSuccess] = useState(false);
    const refContainer = useRef(null);

    const onRequestClose = useCallback(() => {
        if (opened) {
            setOpened(false);
        }
    }, [opened, setOpened]);

    // eslint-disable-next-line
    const onSuccess = useCallback(() => {
        setSuccess(true);
    }, [setSuccess]);

    const onClickButton = useCallback(() => {
        if (!formOpened) {
            setFormOpened(true);
        }
    }, [formOpened, setFormOpened]);

    useEffect(() => {
        setOpened(true);
    }, [setOpened]);

    useEffect(() => {
        let timeout = null;
        if (success) {
            timeout = setTimeout(() => {
                setOpened(false);
            }, closeAfterSuccess);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [success, setOpened, closeAfterSuccess]);

    useEffect(() => {
        const container = refContainer.current;
        let disabled = false;
        if ((type === 'banner' && formOpened) || type === 'modal') {
            disableBodyScroll(container);
            disabled = true;
        }
        return () => {
            if (disabled) {
                enableBodyScroll(container);
            }
        };
    }, [type, formOpened]);

    if (!hasMessages) {
        return null;
    }

    const TypeComponent = type === 'banner' ? Banner : Modal;
    // const formIsModal = type === 'banner';
    const formIsModal = false;

    const formElement = success ? (
        <div className={styles.thankYou}>
            <FormattedMessage defaultMessage="Merci" description="Success message" />
        </div>
    ) : (
        <>
            {type === 'modal' && formLabel === null ? (
                <div className={styles.description}>
                    <FormattedMessage
                        defaultMessage="Inscription à l’infolettre"
                        description="Modal title"
                    />
                </div>
            ) : null}
            {type === 'modal' && formLabel !== null ? (
                <div className={styles.description}>{formLabel}</div>
            ) : null}
            <SubscriptionField
                className={styles.form}
                inputClassName={styles.input}
                buttonClassName={styles.button}
                buttonIcon={<div className={styles.icon}>→</div>}
                source={`Newsletter-${type}`}
                onSubscribed={onSuccess}
                autoFocus={!withForm}
            />
        </>
    );

    return (
        <>
            <TypeComponent
                className={classNames([
                    styles.container,
                    styles[type],
                    {
                        [styles.formOpened]: formOpened,
                        [styles.success]: success === true,
                        [className]: className !== null,
                    },
                ])}
                opened={opened}
                onClosed={closePopup}
                onClickSafe={onRequestClose}
                slot="bottom"
            >
                <div className={styles.inner} ref={refContainer}>
                    <div
                        className={classNames([
                            styles.box,
                            {
                                [styles.withColor]: backgroundColor !== null,
                                [styles.withInfolettreBackground]: withInfolettreBackground,
                                [styles.withSeasonBackground]: withSeasonBackground,
                                [styles[season]]: season !== null,
                            },
                        ])}
                        style={{ backgroundColor, color: textColor }}
                    >
                        {icons[iconId]}
                        {withForm ? (
                            <div className={styles.label}>
                                <h4
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                                {formElement}
                            </div>
                        ) : (
                            <TransitionGroup className={styles.wrapper}>
                                {formOpened && !formIsModal ? (
                                    <CSSTransition key="form" classNames={styles} timeout={20000}>
                                        <div className={styles.label}>{formElement}</div>
                                    </CSSTransition>
                                ) : (
                                    <CSSTransition
                                        key="indications"
                                        classNames={styles}
                                        timeout={20000}
                                    >
                                        <Button
                                            href={url}
                                            external
                                            className={styles.label}
                                            onClick={url !== null ? onRequestClose : onClickButton}
                                            onPointerDown={
                                                url !== null ? onRequestClose : onClickButton
                                            }
                                        >
                                            <strong
                                                className={styles.title}
                                                dangerouslySetInnerHTML={{ __html: title }}
                                            />
                                            <span
                                                className={styles.callToAction}
                                                style={{ backgroundColor: callToActionColor }}
                                                dangerouslySetInnerHTML={{ __html: callToAction }}
                                            />
                                        </Button>
                                    </CSSTransition>
                                )}
                            </TransitionGroup>
                        )}
                    </div>

                    <CloseCircleButton className={styles.closeButton} onClick={onRequestClose} />
                </div>
            </TypeComponent>
            {formOpened && formIsModal ? (
                <NewsletterFormModal
                    source={`Newsletter-${type}`}
                    closePopup={onRequestClose}
                    onSuccess={onSuccess}
                />
            ) : null}
            {type === 'banner' ? (
                <div
                    className={classNames([
                        styles.safe,
                        {
                            [styles.opened]: formOpened && opened,
                        },
                    ])}
                />
            ) : null}
        </>
    );
}

NewsletterPopup.propTypes = propTypes;
NewsletterPopup.defaultProps = defaultProps;

export default NewsletterPopup;
