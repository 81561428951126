import Loadable from 'react-loadable';

export { default as Default } from './Image';
export { default as Background } from './BackgroundImage';

export const BellPunchClub = Loadable({
    loader: () =>
        import('../blocks/bell-punch-club').then(
            (Components) => Components.HeaderImage,
        ),
    loading: () => null,
});
